import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'

import Alert from '../../Components/alert'
import ModalAlert from '../../Components/modalAlert'
import BottomButton from '../../Components/bottomButton'

export default class WithdrawAmount extends Base{
    state = {
        token : '',
        landing_contact_us : {fullname : '', email : '', message : ''},
        type : 'Investment',
        withdraw_amount : '',
        alert : {
            message : '', type : '', is_alert : false
        },
        modal_alert : {title : '', message : ''},
        user_data : {wallet_data : {}},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

        await this.get_auth()
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
                },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                data.wallet_data = data.trader[0].trader_wallet[0]
                await this.setState({user_data : data})
            }
            else{
                await localStorage.clear()
                window.location.href = '/'
            }
        } 
        catch (e) {
            var modal_alert = {title : 'Alert', message : e.message}
            await this.setState({modal_alert : modal_alert})
            this.$('#modalAlert').modal('show')

            this.$('#modalAlert').on('hidden.bs.modal', function (e) {
                window.location.reload()
            })
        }
    }

    async changeAmount(value){
        await this.setState({alert : {message : '', type : '', is_alert : false}})

        var amount = this.state.topup_amount
        amount = value.replace(/,/g,'')
        await this.setState({withdraw_amount : amount})
    }

    async toNext(){
        if(this.state.withdraw_amount == ''){
            await this.setState({alert : {message : 'Please insert amount', type : 'error', is_alert : true}})
        }
        else if(parseFloat(this.state.user_data.wallet_data.cash) < this.state.withdraw_amount){
            await this.setState({alert : {message : `You don't have a sufficient balance`, type : 'error', is_alert : true}})
        }
        else{
            await localStorage.setItem('withdraw_amount', this.state.withdraw_amount)
            window.location.href = '/cash/withdraw/payment-received'
        }
    }

    render(){
        return (
            <>
            <div className="p-4">

                <HeaderNav title={'Withdraw'} show_back={true} />

                <div className="row m-0">
                    <div className="col-12 p-0">
                        <p className="m-0">Current Ballance : </p>
                        <h4>{this.euro_currency} {parseFloat(this.state.user_data.wallet_data.cash).toLocaleString(this.currency_format)}</h4>
                    </div>

                    <div className="col-12 p-0 mt-2">
                        <p>Please enter the amount you want to withdraw</p>
                    </div>
                    <div className="col-12 p-0">
                    <form autoComplete="off">
                    {
                        this.state.alert.is_alert ?
                        <Alert type="danger" message={this.state.alert.message} /> : <></>
                    }
                    
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <h2 className="m-0">{this.euro_currency}</h2>
                            </div>
                            <input className="form-control form-control-lg form-control-transparent font-weight-bold" value={(this.state.withdraw_amount == '' ? this.state.withdraw_amount : parseFloat(this.state.withdraw_amount).toLocaleString(this.currency_format) )} onChange={(value)=>this.changeAmount(value.target.value)} />
                        </div>

                        <BottomButton buttonAction={()=>this.toNext()} is_disabled={false} title={'Next'} />

                    </form>
                        
                        
                    </div>
                </div>

                <ModalAlert title={this.state.modal_alert.title} message={this.state.modal_alert.message} />

            </div>
            </>
        )
    }
}