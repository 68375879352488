import React from 'react'
import Base from '../Utils/Base'
import {BrowserRouter, Route} from 'react-router-dom'
import BaseLayout from '../Layout/base'
import BaseAuth from '../Auth/base'

export default class BaseRouter extends Base {
    render(){
        return (
            <BrowserRouter>
                <Route exact path={"/auth/*"} component={BaseAuth} />
                <Route exact path={"/"} component={BaseLayout} />

                <Route exact path={"/notification*"} component={BaseLayout} />
                <Route exact path={"/package*"} component={BaseLayout} />
                <Route exact path={"/profile*"} component={BaseLayout} />
                <Route exact path={"/help*"} component={BaseLayout} />
                <Route exact path={"/history-transaction*"} component={BaseLayout} />
                <Route exact path={"/message*"} component={BaseLayout} />
                <Route exact path={"/cash*"} component={BaseLayout} />
                <Route exact path={"/investment*"} component={BaseLayout} />

                <Route exact path={"/terms-condition"} component={BaseLayout} />
                <Route exact path={"/referral-dashboard"} component={BaseLayout} />
            </BrowserRouter>
        )
    }
}