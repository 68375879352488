import React from 'react';
import Base from '../Utils/Base';

export default class ModalAlert extends Base{
  render(){
    return (
        <div className="modal fade p-3" data-backdrop="static" id="modalAlert" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header p-2">
                    <h5 className="modal-title">{this.props.title}</h5>
                </div>
                <div className="modal-body py-2 px-3">
                    <p className="m-0">{this.props.message}</p>
                </div>
                <div className="modal-footer p-2">
                    <button type="button" data-dismiss="modal" className="btn btn-primary btn-sm">Close</button>
                </div>
                </div>
            </div>
        </div>
    );
  }
}
// export default App;
