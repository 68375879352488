import React from 'react';
import Base from '../Utils/Base';

import ModalAlert from '../Components/modalAlert'
import Alert from '../Components/alert'

import HeaderNav from '../Components/headerNav'

export default class ReferralCode extends Base{
    state = {
        token : '',
        referral_code : '',
        alert : {
            message : '', type : '', is_alert : false
        },
        is_disable : false
    }

    async componentDidMount() {
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

        console.log(token)
    }
    
    async next(){
        if(this.state.referral_code == ''){
            window.location.href = '/auth/otp'
        }
        else{
            await this.setState({is_disable : true})
            this.alert_show(`Please Wait...`, 'success')

            try {
                var response = await this.axios.post(this.url + '/referral-code/use', {
                    referral_code : this.state.referral_code
                }, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : this.state.token
                  },
                });

                if(response.data.status == 'success'){
                    setTimeout(()=>{
                        window.location.href = '/auth/otp'
                    }, 2000);
                }
                else{
                    await this.setState({is_disable : false})
                    this.alert_show(response.data.message, 'danger')
                }
            } 
            catch (e) {
                console.log(e.message)
            }
        }
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async changeInput(value){
        await this.setState({alert : {message : '', type : '', is_alert : false}})

        await this.setState({referral_code : value})
    }

    render(){
        return (
        <div className="p-4" style={{height : '100vh'}}>
            <HeaderNav title={'Referral Code'} show_back={true} />

            <form className="pt-4" autoComplete="off">
            {
                this.state.alert.is_alert ?
                <Alert type={this.state.alert.type} message={this.state.alert.message} /> : <></>
            }

                <div className="input-group mb-4">
                    <input type="text" className="form-control form-control-lg" placeholder="Input your referral code" value={this.state.referral_code} onChange={(value)=>this.changeInput(value.target.value)} />
                </div>
                <p>Just Press next if you don't have a Referral Code</p>

                <div className="py-3">
                    <button className="btn btn-primary w-100" type='button' disabled={this.state.is_disable} onClick={()=>this.next()}>Next</button>
                </div>
            </form>

            <ModalAlert title={'Alert'} message={this.state.alertMessage} />
        </div>
        );
    }
}
// export default App;
