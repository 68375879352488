import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'

export default class WithdrawPackage extends Base{
    state = {
        token : '',
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
    }

    async next(){
        window.location.href = '/cash/withdraw/amount'
    }

    render(){
        return (
            <>
            <div className="p-4">

                <HeaderNav title={'Pick Package'} show_back={true} />

                <form className="pt-4" autoComplete="off">
                

                    <div className="fixed-bottom p-4">
                        <button className="btn btn-primary w-100" type='button' onClick={()=>this.next()}>Next</button>
                    </div>

                </form>
            </div>
            </>
        )
    }
}