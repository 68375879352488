import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'
import Alert from '../../Components/alert'
import ModalAlert from '../../Components/modalAlert'
import BottomButton from '../../Components/bottomButton'

export default class TopUpPayment extends Base{
    state = {
        data_arr : [],
        wallet_data : {wallet_name : '', wallet_no : ''},
        topup_amount : '',
        alert : {
            message : '', type : '', is_alert : false
        },
        alertMessage : '',
        is_disable : false,
        token : '',
    }

    async componentDidMount() {
        var token = await localStorage.getItem('token')
        var topup_amount = await localStorage.getItem('top_up_amount')
        await this.setState({token : token, topup_amount : topup_amount})
    }
    
    async changeInput(value, type){
        await this.setState({alert : {message : '', type : '', is_alert : false}})

        var wallet_data = this.state.wallet_data
        wallet_data[type] = value

        await this.setState({wallet_data : wallet_data})

    }

    async toSubmit(){
        // await localStorage.setItem('wallet_data', JSON.stringify(this.state.wallet_data))
        // window.location.href = '/cash/top-up/otp'

        if(this.state.wallet_data.wallet_name == ''){
            this.alert_show('Wallet Name cannot be null', 'danger')
        }
        else if(this.state.wallet_data.wallet_name == ''){
            this.alert_show('Wallet ID Number cannot be null', 'danger')
        }
        else{
            var data = {}
            data.amount = this.state.topup_amount
            data.wallet_name = this.state.wallet_data.wallet_name
            data.wallet_no = this.state.wallet_data.wallet_no
    
            try {
                await this.setState({is_disable : true})
                this.alert_show('Please Wait...', 'info')
    
                var response = await this.axios.post(this.url + '/transaction/top-up', data, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : this.state.token
                  },
                });
    
                if(response.data.status == 'success'){
                    this.alert_show('Success!', 'success')
                    await localStorage.removeItem('top_up_amount')
                    
                    var trader_wallet = response.data.trader_wallet
                    await localStorage.setItem('trader_wallet_id', trader_wallet.id)
    
                    setTimeout(()=>{
                        window.location.href = '/cash/top-up/submit'
                    }, 2000);
                }
                else{
                    await this.setState({alert : {message : '', type : '', is_alert : false}})
                    await this.setState({is_disable : false})
                    this.alert_show(response.data.message, 'danger')
                }
    
            } 
            catch (e) {
                await this.setState({alert : {message : '', type : '', is_alert : false}})
                await this.setState({is_disable : false})
                await this.setState({alertMessage : e.message})
                this.$('modalAlert').modal('show')
            }
        }

    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async removeAlert(){
        await this.setState({alert : {message : '', type : '', is_alert : false}})
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Payment Method'} show_back={true} />

                    <form className="pt-4" autoComplete="off">
                    <div class="alert alert-primary" role="alert">
                        Please insert your BTC Wallet information, so we can verify your transaction.
                    </div>
                        {
                            this.state.alert.is_alert ?
                            <Alert type={this.state.alert.type} message={this.state.alert.message} /> : <></>
                        }
                        <div className="input-group mb-2">
                            <input type="text" className="form-control" placeholder="Wallet ID Name" value={this.state.wallet_data.wallet_name} onFocus={()=>this.removeAlert()} onChange={(value)=>this.changeInput(value.target.value, 'wallet_name')} />
                        </div>
                        <div className="input-group mb-2">
                            <input type="text" className="form-control" placeholder="Wallet ID Number" value={this.state.wallet_data.wallet_no} onFocus={()=>this.removeAlert()} onChange={(value)=>this.changeInput(value.target.value, 'wallet_no')} />
                        </div>

                    </form>
                    
                    <BottomButton buttonAction={()=>this.toSubmit()} is_disabled={this.state.is_disable} title={'Next'} />
                    
                </div>

                <ModalAlert title={'Alert'} message={this.state.alertMessage} />

            </div>
            </>
        )
    }
}