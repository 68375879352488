import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'
import BottomButton from '../../Components/bottomButton'

export default class TopUpInvoice extends Base{
    state = {
        token : '',
        topup_amount : 0,
        data_arr : [],
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var topup_amount = await localStorage.getItem('top_up_amount')
        await this.setState({token : token, topup_amount : topup_amount})
    }

    async toPayment(){
        window.location.href = '/cash/top-up/payment-method'
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Top Up Invoice'} show_back={true} />
                    
                    <div className="row m-0">
                        <div className="col-12 p-0 mt-4 border-bottom">
                            <div className="row m-0">
                                <div className="col p-0">
                                    <p>Invoice</p>
                                </div>
                                <div className="col p-0 text-right">
                                    <p>{this.euro_currency} {parseFloat(this.state.topup_amount).toLocaleString(this.currency_format)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 p-0 mt-3">
                            <div className="row m-0">
                                <div className="col p-0">
                                    <p><b>Total</b></p>
                                </div>
                                <div className="col p-0 text-right">
                                    <p>{this.euro_currency} {parseFloat(this.state.topup_amount).toLocaleString(this.currency_format)}</p>
                                </div>
                            </div>
                        </div>

                        <BottomButton buttonAction={()=>this.toPayment()} is_disabled={false} title={'Next'} />

                    </div>
                    
                </div>
            </div>
            </>
        )
    }
}