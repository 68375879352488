import React from 'react';
import Base from "../Utils/Base";
import { BrowserRouter, Route } from "react-router-dom";
import Login from '../Auth/Login'
import SignUp from '../Auth/SignUp'
import ReferralCode from '../Auth/referral'
import Otp from '../Auth/otp'

import ForgetPassword from '../Auth/ForgetPassword'
import SecurityQuestion from '../Auth/ForgetPassword/securityQuestion'
import ForgetPasswordSuccess from '../Auth/ForgetPassword/success'

export default class AuthRouter extends Base {
    render (){
        return (
            <BrowserRouter>
                <Route exact path={"/auth/login"} component={Login} />
                <Route exact path={"/auth/signup"} component={SignUp} />
                <Route exact path={"/auth/referral"} component={ReferralCode} />
                <Route exact path={"/auth/otp"} component={Otp} />

                <Route exact path={"/auth/forget-password"} component={ForgetPassword} />
                <Route exact path={"/auth/security-question"} component={SecurityQuestion} />
                <Route exact path={"/auth/forget-password/success"} component={ForgetPasswordSuccess} />

            </BrowserRouter>
        )
    }
}