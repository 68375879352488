import React from 'react';
import Base from '../Utils/Base';

export default class ListDetail extends Base{
  render(){
    return (
        <>
        <div className="col-12 p-0">
            <div className="row m-0">
                <div className="col p-0">
                    <p>{this.props.title}</p>
                </div>
                <div className="col p-0 text-right">
                    <p className="text-capitalize">{this.props.value}</p>
                </div>
            </div>
        </div>
        </>
    );
  }
}
// export default App;
