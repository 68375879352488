import React, {Component} from 'react'
import '../App.css'
import axios from 'axios'
import moment from 'moment'
import Jquery from 'jquery'

import 'popper.js'
import 'bootstrap'

import io from 'socket.io-client'

export default class Base extends Component{
    host = "vue99.com"
    // host = 'https://vue99-api.propertigo.id'
    // url = 'https://api.' + this.host
    url = 'https://vue99-api.propertigo.id'

    euro_currency = '€'
    currency_format = 'eu-EU'
    
    axios = axios
    moment = moment
    $ = Jquery

    io = io

    no_user_image = require('../asset/image/png/no_profile_picture.png')

    logo = require('../asset/image/png/logo.png')
    logo_small = require('../asset/image/png/logo_small.png')

    landing1 = require('../asset/image/jpg/charles-forerunner-3fPXt37X6UQ-unsplash.jpg')
    landing2 = require('../asset/image/png/Illustration_2@2x.png')
    landing3 = require('../asset/image/png/Illustration_1@2x.png')
    landing4 = require('../asset/image/png/Product_1@2x.png')
    landing_quote = require('../asset/image/png/Quote_1@2x.png')
}