import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

import Alert from '../Components/alert'

export default class PackagePayment extends Base{

    state = {
        token : '',
        alert : {
            message : '', type : '', is_alert : false
        },
        upgrade_data : {membership : {name : '', deposit : ''}, image : {image : '', original_rotation : '', image_display : ''}},
        user_data : {trader_wallet : {}, wallet_cash : ''},
        is_disable : false,
        total : 0,
        package_deposit : 0,
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var upgrade_data = await localStorage.getItem('upgrade_data')
        upgrade_data = JSON.parse(upgrade_data)
        console.log(upgrade_data)
        if(upgrade_data == null){
            window.location.href = '/package'
        }
        await this.setState({token : token, upgrade_data : upgrade_data})

        await this.get_auth()

        var total = 0
        var wallet_cash = this.state.user_data.trader_wallet.cash
        var package_deposit = this.state.upgrade_data.membership.deposit
        total = parseFloat(wallet_cash) - parseFloat(package_deposit)
        
        await this.setState({total : total, package_deposit : package_deposit})
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                data.trader_wallet = data.trader[0].trader_wallet[0]
                data.wallet_cash = data.trader[0].trader_wallet[0].cash.replace(this.euro_currency+' ', '').replace('.','')
                await this.setState({user_data : data})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async toNext(){
        if(parseFloat(this.state.user_data.wallet_cash) < parseFloat(this.state.package_deposit)){
            window.location.href = '/cash/top-up'
        }
        else{
            var data = this.state.upgrade_data
    
            try {
                await this.setState({is_disable : true})
                var response = await this.axios.post(this.url + '/transaction/purchase', data, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : this.state.token
                  },
                });
    
                if(response.data.status == 'success'){
                    await localStorage.removeItem('upgrade_data')
                    window.location.href = '/package/success'
                }
                else{
                    await this.setState({is_disable : false})
                }
    
            } 
            catch (e) {
                console.log(e.message)
            }
        }    
        // window.location.href = '/package/success'
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Payment Package'} show_back={true} />
                    
                    <div className="row m-0 pb-5 mb-5">

                        <div className="col-12 p-0 mt-4 border-bottom">
                            <div className="row m-0">

                                {
                                    parseFloat(this.state.user_data.wallet_cash) < parseFloat(this.state.package_deposit) ?
                                    <div className={"col-12 p-3 mb-3 border-left-contain bg-dark"}>
                                        <h5 className="text-primary">Alert</h5>
                                        <p className="m-0">Your cash isn't sufficient. Please Top-Up cash before adding a Package </p>
                                    </div>
                                    :<></>
                                }

                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <div className="col p-0">
                                            <p>Cash</p>
                                        </div>
                                        <div className="col p-0 text-right">
                                            <p>{this.euro_currency} {parseFloat(this.state.user_data.trader_wallet.cash).toLocaleString(this.currency_format)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <div className="col p-0">
                                            <p>Initial Deposit</p>
                                        </div>
                                        <div className="col p-0 text-right">
                                            <p>- {this.euro_currency} {this.state.upgrade_data.membership.deposit}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-12 p-0 mt-3">
                            <div className="row m-0">
                                <div className="col p-0">
                                    <p><b>Total</b></p>
                                </div>
                                <div className="col p-0 text-right">
                                    <p><b>{this.state.total < 0 ? '-' : ''} {this.euro_currency} {this.state.total.toLocaleString(this.currency_format)}</b></p>
                                </div>
                            </div>
                        </div>

                        {
                            this.state.user_data.wallet_cash != '0,00' ?
                            <div className={"col-12 p-3 mt-3 border-top-contain bg-dark"}>
                                <div className="row m-0">
                                    <div className="col-auto p-0">
                                    <p className="text-primary" style={{fontSize : '2.5rem'}}><i class="fas fa-exclamation"></i></p>
                                    </div>
                                    <div className="col">
                                        <p className="m-0">
                                        <b>Investment Condition</b><br/>
                                        Max Investment 22nd for each month
                                        Trading will start 1st at next month 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            :<></>
                        }

                    </div>

                    <BottomButton buttonAction={()=>this.toNext()} is_disabled={this.state.is_disable} title={(parseFloat(this.state.user_data.wallet_cash) < parseFloat(this.state.package_deposit)) ? 'Top Up' : 'Next'} />

                </div>
            </div>
            </>
        )
    }
}