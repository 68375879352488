import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import ListDetail from '../Components/list_detail'
import BottomButton from '../Components/bottomButton'

export default class EndPackage extends Base{

    state = {
        token : '',
        total : 0,
        data_arr : [],
        package_data : {membership : {id : ''}, period : ''},
        penalty : {percentage : 0},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var package_data = await localStorage.getItem('packageDetail')
        package_data = JSON.parse(package_data)
        await this.setState({token : token, package_data : package_data})

        console.log(this.state.package_data)

        await this.get_penalty()

        var fee = package_data.amount * (this.state.penalty.percentage/100)

        var data_arr = [
            {title : 'Amount Package', value : package_data.amount},
            {title : 'End Package Fee', value : fee},
        ]
        var total = 0
        total = parseFloat(package_data.amount) + parseFloat(fee)
        await this.setState({data_arr : data_arr, total : total})
    }

    async get_penalty(){
        console.log(this.url + '/withdrawal-penalty?id=&package_id=' + this.state.package_data.membership.id + '&period=' + this.state.package_data.period)
        try {
            var response = await this.axios.get(this.url + '/withdrawal-penalty?id=&package_id=' + this.state.package_data.membership.id + '&period=' + this.state.package_data.period, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                await this.setState({penalty : data})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.href = '/'
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async toNext(){
        var data = {}
        data.package = {id : this.state.package_data.id}
        data.membership = {id : this.state.package_data.membership.id}

        try {
            await this.setState({is_disable : true})
            var response = await this.axios.post(this.url + '/transaction/end', data, {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
                },
            });

            if(response.data.status == 'success'){
                window.location.href = '/package/end/success'
            }

        } 
        catch (e) {
            console.log(e.message)
        }
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'End Package'} show_back={true} />
                    
                    <div className="row m-0 pb-5 mb-5">

                        <div className="col-12 p-0 mt-4 border-bottom">
                            <div className="row m-0">

                                {
                                    this.state.data_arr.map((data,index)=>(
                                        <ListDetail key={index} title={data.title} value={this.euro_currency + ' ' + parseFloat(data.value).toLocaleString('eu-EU')} />
                                    ))
                                }

                            </div>
                        </div>

                        <div className="col-12 p-0 mt-3">
                            <div className="row m-0">
                                <div className="col p-0">
                                    <p><b>Total</b></p>
                                </div>
                                <div className="col p-0 text-right">
                                    <p><b>{this.euro_currency} {this.state.total.toLocaleString(this.currency_format)}</b></p>
                                </div>
                            </div>
                        </div>

                        <div className={"col-12 p-3 mt-3 border-top-contain bg-dark"}>
                            <div className="row m-0">
                                <div className="col-auto p-0">
                                <p className="text-primary" style={{fontSize : '2.5rem'}}><i className="fas fa-exclamation"></i></p>
                                </div>
                                <div className="col">
                                    <p className="m-0">
                                    <b>End Package Condition</b><br/>
                                    Max End Package 22nd for each month
                                    Trading will start 1st at next month
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <BottomButton buttonAction={()=>this.toNext()} is_disabled={this.state.is_disable} title={'Next'} />

                </div>
            </div>
            </>
        )
    }
}