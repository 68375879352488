import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import HeaderNav from '../Components/headerNav'
import Sort from '../Components/sort'
import ModalAlert from '../Components/modalAlert'

export default class HistoryTransaction extends Base{
    state = {
        token : '',
        data_arr : [],
        sort : ['recent', 'oldest'],
        sort_selected : '',
        modal_alert : {title : '', message : ''},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var sort_selected = this.state.sort[0]
        await this.setState({token : token, sort_selected : sort_selected})

        await this.get_data()

        // var data_arr = [
        //     {id : 0, title : 'Topup', amount : '5000', date : '05/22', status : 'completed'}
        // ]

        // await this.setState({data_arr : data_arr})
    }

    async get_data(){
        try {
            var response = await this.axios.get(this.url + '/trader-wallet-history?id=&type=&sort='+this.state.sort_selected, {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
                },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                for(var x in data){
                    data[x].transaction_date_format = this.moment(data[x].transaction_date).format('MM/DD')
                }
                await this.setState({data_arr : data})
            }
        } 
        catch (e) {
            var modal_alert = {title : 'Alert', message : e.message}
            await this.setState({modal_alert : modal_alert})
            this.$('#modalAlert').modal('show')
        }
    }

    async toDetail(index){
        window.location.href = '/history-transaction/detail/' + this.state.data_arr[index].id
    }

    async change_sort(sort){
        await this.setState({data_arr : [], sort_selected : sort})
        await this.get_data()
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'History Transaction'} show_back={true} />
                    
                    <div className="row m-0 mt-5">
                        <div className="col-12 p-0">
                            <div className="row m-0">
                                <div className="col p-0">
                                    {this.state.data_arr.length} Transaction
                                </div>
                                <div className="col p-0 text-right">
                                    <Sort sort_selected={this.state.sort_selected} sort_arr={this.state.sort} changeSort={(data)=>this.change_sort(data)} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 p-0 mt-2">
                            <div className="row m-0">

                            {
                                this.state.data_arr.map((data, index)=>(
                                    <div className="col-12 p-0 py-3 transaction-list" onClick={()=>this.toDetail(index)} key={index}>
                                        <div className="row m-0">
                                            <div className="col-auto p-0">
                                                <span className="text-secondary">{data.transaction_date_format}</span>
                                            </div>
                                            <div className="col">
                                                <p className="m-0 text-capitalize"><b>{data.info}</b></p>
                                                <p className="m-0"><span className={"text-" + (data.type == 'in' ? 'success' : 'danger')}>
                                                {data.type == 'in' ? '+' : '-'}</span> {parseFloat(data.amount).toLocaleString(this.currency_format)}</p>
                                            </div>
                                            <div className="col-auto p-0 text-right">
                                            <span className={"rounded text-capitalize badge-transaction-" + (data.status == 'process' ? 'warning' : data.status == 'success' ? 'success' : 'danger')}>{data.status}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            </div>
                        </div>
                    </div>

                </div>

                <ModalAlert title={this.state.modal_alert.title} message={this.state.modal_alert.message} />

            </div>
            </>
        )
    }
}