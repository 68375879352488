import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import HeaderNav from '../Components/headerNav'

export default class Message extends Base{
    async toChatRoom(){
        window.location.href = '/message/chat/1'
    }

    async componentDidMount(){
        var token = '123'
        await this.setState({token : token})
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Message'} show_back={true} />
                    
                    <div className="row m-0 mt-3">
                        <div className="col-12 p-0 py-3 border-bottom-gray" onClick={()=>this.toChatRoom()}>
                            <div className="row m-0">

                                <div className="col-auto p-0">
                                    <img src={this.no_user_image} className="img-profile-picture" />
                                </div>
                                <div className="col p-0 pl-3 align-self-center">
                                    <div className="row m-0">
                                        <div className="col p-0">
                                            <h5 className="text-primary m-0">Name</h5>
                                        </div>
                                        <div className="col-auto p-0 text-right">
                                            <h5 className="text-primary m-0">+1</h5>
                                        </div>
                                    </div>
                                    <p className="m-0">Welcome Back</p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </>
        )
    }
}