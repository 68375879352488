import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import HeaderNav from '../Components/headerNav'
import ModalAlert from '../Components/modalAlert'

export default class Notification extends Base{
    state = {
        token : '',
        alertMessage : '',
        data_arr : [],
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

        await this.get_data()
        await this.set_read()
    }

    async get_data(){
        try {
            var response = await this.axios.get(this.url + '/notif', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                console.log(data)
                
                for(var x in data){
                    data[x].notif_time = this.moment(data[x].created_date).fromNow()
                }
                
                await this.setState({data_arr : data})
            }
            else{
                await this.setState({alertMessage : response.data.message})
                this.$('modalAlert').modal('show')
            }

        } 
        catch (e) {
            await this.setState({alertMessage : e.message})
            this.$('modalAlert').modal('show')
        }
    }

    async set_read(){
        console.log(this.state.token)
        try {
            var response = await this.axios.put(this.url + '/notif/set-all-read', {

            }, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data
                console.log(data)
            }
            else{
                await this.setState({alertMessage : response.data.message})
                this.$('modalAlert').modal('show')
            }

        } 
        catch (e) {
            await this.setState({alertMessage : e.message})
            this.$('modalAlert').modal('show')
        }
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Notification'} show_back={true} />
                    
                    <div className="row m-0 mt-3">
                        {
                            this.state.data_arr.map((data, index)=>(
                                <div className="col-12 p-2 notif-list" key={index}>
                                    <p className="m-0 mt-2">{data.message}</p>
                                    <small className="m-0">{data.notif_time}</small>
                                </div>
                            ))
                        }

                    </div>
                </div>

                <ModalAlert title={'Alert'} message={this.state.alertMessage} />

            </div>
            
            </>
        )
    }
}