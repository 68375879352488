import React from 'react'
import Base from '../Utils/Base'

export default class SectionWelcome extends Base{
    async joinNow(){
        window.location.href = '/auth/login'
    }

    async upgradePlan(){
        window.location.href = '/upgrade-plan'
    }

    render(){
        return (
            <>
            <div className="col-12 p-3 bg-dark border-top-contain">
                <h5 className="text-primary m-0">Welcome!</h5>
                <p className="m-0 mt-2">
                Welcome <b className="text-capitalize">{this.props.user_data.name}</b>,  congratulations  on your approved membership. Enjoy a new era of your investment journey to success and achieve financial freedom.
                </p>
            </div>

            <div className="row m-0 mt-4">

                <div className="col-auto p-0">
                    <img src={this.no_user_image} className="img-profile-picture" />
                </div>
                <div className="col py-2">
                    <p className="m-0">Welcome Back</p>
                    <h5 className="text-primary m-0 text-capitalize">{this.props.user_data.name} </h5>
                </div>

            </div>
            </>
        )
    }
}