import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'
import Alert from '../../Components/alert'
import BottomButton from '../../Components/bottomButton'

export default class PaymentReceived extends Base{
    state = {
        token : '',
        landing_contact_us : {fullname : '', email : '', message : ''},
        type : 'Investment',
        withdraw_amount : 0,
        received_arr : [
            {value : 'wallet', title : 'Wallet'},
            {value : 'reinvest', title : 'Re-Invest'}
        ],
        received_type : '',
        alert : {
            message : '', type : '', is_alert : false
        },
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var withdraw_amount = await localStorage.getItem('withdraw_amount')
        await this.setState({token : token, withdraw_amount : withdraw_amount})
    }

    async received_type_selected(val){
        await this.setState({alert : {message : '', type : '', is_alert : false}})
        await this.setState({received_type : val})
    }

    async next(){
        if(this.state.received_type == 'wallet'){
            window.location.href = '/cash/withdraw/invoice'
        }
        else if(this.state.received_type == 'reinvest'){
            window.location.href = '/package/upgrade-plan'
        }
        else if(this.state.received_type == ''){
            await this.setState({alert : {message : 'Please select a method', type : 'error', is_alert : true}})
        }
    }

    render(){
        return (
            <>
            <div className="p-4">

                <HeaderNav title={'Payment Received'} show_back={true} />

                <form className="pt-4" autoComplete="off">
                    {
                        this.state.alert.is_alert ?
                        <Alert type="danger" message={this.state.alert.message} /> : <></>
                    }

                    {
                        this.state.received_arr.map((data, index)=>(
                            <div className="custom-control custom-radio mb-2" key={index}>
                                <input className="custom-control-input" type="radio" name="receivedType" id={"data_"+data.value} value={data.value} onChange={()=>this.received_type_selected(data.value)} checked={this.state.received_type == data.value} />
                                <label className="custom-control-label" htmlFor={"data_"+data.value}>
                                    {data.title}
                                </label>
                            </div>
                        ))   
                    }

                    <BottomButton buttonAction={()=>this.next()} is_disabled={false} title={'Next'} />


                </form>
            </div>
            </>
        )
    }
}