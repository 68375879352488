import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

import ListPackage from './listPackage'

export default class PackageIndex extends Base{

    state = {
        token : '',
        package_arr : [],
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

        await this.get_auth()
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                var package_arr = data.trader[0].package
                console.log(package_arr)
                await this.setState({package_arr : package_arr})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.href = '/'
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async toNext(){
        window.location.href = '/package/upgrade-plan'
    }

    async toDetailPackage(index){
        // console.log(this.state.package_arr[index])
        // await localStorage.setItem('packageDetail', JSON.stringify(this.state.package_arr[index]))
        window.location.href = '/package/trader/' + this.state.package_arr[index].id
    }

    render(){
        return (
            <>

            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Package'} show_back={true} />

                    <div className="row m-0">
                        <div className="col-12 p-0 mt-4">
                            
                            <div className="row m-0 pb-5 mb-3">
                            {
                                this.state.package_arr.length == 0 ?
                                <div className="col-12 p-3 bg-dark border-top-contain">
                                    <h5 className="title m-0">Applied Now!</h5>
                                    <p className="m-0 mt-2">
                                    Upgrade your plan to get more benefits.<br/><br/>
                                    You're currently not assigned to any membership program
                                    </p>
                                </div>
                                :
                                <>
                                {
                                    this.state.package_arr.map((data, index)=>(
                                        <ListPackage package_data={data} toDetailPackage={()=>this.toDetailPackage(index)} />
                                    ))
                                }
                                </>
                            }
                            </div>

                            <BottomButton buttonAction={()=>this.toNext()} is_disabled={false} title={this.state.package_arr.length == 0 ? 'Next' : 'Add Package'} />

                        </div>
                    </div>

                </div>
            </div>
            </>
        )
    }
}