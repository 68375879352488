import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'
import BottomButton from '../../Components/bottomButton'

export default class TopUp extends Base{
    state = {
        data_arr : [],
        topup_arr : [
            {id : 0, value : '10000', selected : false},
            {id : 1, value : '30000', selected : false},
            {id : 2, value : '50000', selected : false},
            {id : 3, value : '100000', selected : false}
        ],
        topup_amount : '',
        token : ''
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
    }

    async selectAmount(index){
        var amount = this.state.topup_arr
        for(var x in amount){
            amount[x].selected = false
        }
        amount[index].selected = true

        await this.setState({topup_amount : amount[index].value, topup_arr : amount})
    }

    async changeAmount(value){
        var topup_arr = this.state.topup_arr
        for(var x in topup_arr){
            topup_arr[x].selected = false
        }

        var amount = this.state.topup_amount
        amount = value.replace(/,/g,'')
        await this.setState({topup_amount : amount, topup_arr : topup_arr})
    }

    async toInvoice(){
        await localStorage.setItem('top_up_amount', this.state.topup_amount)
        window.location.href = '/cash/top-up/invoice'
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Top Up Cash'} show_back={true} />
                    
                    <div className="row m-0">
                        <div className="col-12 p-0 mt-4">
                            <p>Please enter the amount you want to top up</p>
                        </div>
                        <div className="col-12 p-0 mt-4">
                            <form autoComplete="off">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <h2 className="m-0">{this.euro_currency}</h2>
                                    </div>
                                    <input type="text" className="form-control form-control-lg form-control-transparent font-weight-bold" 
                                    value={(this.state.topup_amount == '' ? this.state.topup_amount : parseFloat(this.state.topup_amount).toLocaleString(this.currency_format) )} onChange={(value)=>this.changeAmount(value.target.value)} />
                                </div>
                            </form>
                        </div>

                        <div className="col-12 p-0 mt-4">
                            <div className="row m-0">
                                {
                                    this.state.topup_arr.map((data, index) => (
                                    <div className={"col-6 mb-2 p-0 " + (index % 2 == 0 ? "pr-1" : "pl-1")}>
                                        <button className={"btn btn-sm w-100 " + (data.selected ? 'btn-warning' : 'btn-primary') } onClick={()=>this.selectAmount(index)}>{this.euro_currency} {parseFloat(data.value).toLocaleString(this.currency_format)}</button>
                                    </div>
                                    ))
                                }
                            </div>
                        </div>

                        <BottomButton buttonAction={()=>this.toInvoice()} is_disabled={false} title={'Next'} />

                    </div>
                    
                </div>
            </div>
            </>
        )
    }
}