import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

export default class ConfirmationPackage extends Base{

    state = {
        package_arr : [],
        package_selected : {id : ''},
        user_info : [],
        upgrade_data : {membership : {name : '', deposit : '', detail : {additional_profit_sharing : 0, guaranteed_roi : 0}}, image : {image : '', original_rotation : '', image_display : ''}},
        user_data : {trader_data : {}},
        
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var upgrade_data = await localStorage.getItem('upgrade_data')
        upgrade_data = JSON.parse(upgrade_data)
        upgrade_data.image.image_display = 'data:image/png;base64,' + upgrade_data.image.image
        
        await this.setState({token : token, upgrade_data : upgrade_data})

        await this.get_auth()

    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var upgrade_data = this.state.upgrade_data

                var data = response.data.data
                // data.trader_data = data.trader[0]
                // if(data.trader_data.metadata != null){
                //     data.trader_data.identity_trader = JSON.parse(data.trader_data.metadata).identity
                //     if(upgrade_data.image.image == ''){
                //         upgrade_data.image.image_display = this.url + '/image/trader-package?file_name=' + data.trader_data.identity_trader.attachment
                //     }
                // }

                var user_info = [
                    {title : 'Full Name', value : data.name},
                    {title : 'Mobil Number', value : data.phone_number},
                    {title : 'ID Type', value : this.state.upgrade_data.identity_type.type},
                    {title : 'ID Number', value : this.state.upgrade_data.identity_number},
                ]

                await this.setState({user_data : data, upgrade_data : upgrade_data, user_info : user_info})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async toNext(){
        window.location.href = '/package/upgrade-plan/payment'
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Confirmation'} show_back={true} />
                    
                    <div className="row m-0 my-4 pb-5">

                        <p>You will choose this plan</p>
                        <div className={"col-12 p-3 mb-3 bg-dark border-top-contain"}>
                            <h4 className="title m-0">{this.state.upgrade_data.membership.name}//</h4>

                            <div>
                            <h5 className="m-0 mt-2">{this.euro_currency} {parseFloat(this.state.upgrade_data.membership.deposit).toLocaleString(this.currency_format)}</h5>
                            <p>Minimum Investment</p>
                            </div>

                            <div>
                            <h5 className="m-0 mt-2">{this.state.upgrade_data.membership.detail.guaranteed_roi}%</h5>
                            <p>Guarantee ROI</p>
                            </div>

                            <div>
                            <h5 className="m-0 mt-2">{this.state.upgrade_data.membership.detail.additional_profit_sharing}%</h5>
                            <p>Profit Share</p>
                            </div>
                        </div>

                        <p>With a full information below</p>
                        <div className={"col-12 p-3 mb-3 bg-dark border-top-contain"}>
                            <div className="row m-0">
                            {
                                this.state.user_info.map((data, index) => (
                                    <div className="col-12 p-0 mb-3" key={index}>
                                        <p className="m-0 text-secondary">{data.title}</p>
                                        <h6 className="m-0">{data.value}</h6>
                                    </div>
                                ))
                            }

                            <div className="col-12 p-0 mb-3">
                                <p className="m-0 text-secondary">Document Proof</p>

                                <div className="ratio-11 mt-3" style={{backgroundImage : "url(" + this.state.upgrade_data.image.image_display +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain', width : '75%'}}></div>
                                
                            </div>

                            </div>

                        </div>
                        
                        <p>Please make sure all the information is correct before you submit.</p>

                    </div>

                    <BottomButton buttonAction={()=>this.toNext()} is_disabled={false} title={'Next'} />

                </div>
            </div>
            </>
        )
    }
}