import React from 'react';
import Base from '../Utils/Base';

export default class Navbar extends Base{
    state = {
        is_sidemenu : false,
        sidemenu_arr : [],
        bottom_menu_arr : [],
        user_data : {role_user : {name : ''}, name : ''},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                data.role_user = data.role[0].role
                
                await this.setState({user_data : data})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.reload()
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async toNotif(){
        window.location.href = '/notification'
    }

    async sideBar(action){
        if(action){
            this.$('body').addClass('no-scroll');
            await this.get_auth()

            var sidemenu_arr = [
                {title : 'Home', icon : 'fas fa-home', nav : '/', is_show : true},
                {title : 'Package', icon : 'fas fa-home', nav : '/package', is_show : true},
                {title : 'Cash', icon : 'fas fa-money-bill-wave', nav : '/cash', is_show : true},
                {title : 'Investment', icon : 'fas fa-chart-line', nav : '/investment', is_show : true},
                {title : 'Referral Dashboard', icon : 'fas fa-user-friends', nav : '/referral-dashboard', is_show : true},
                {title : 'History Transaction', icon : 'fas fa-history', nav : '/history-transaction', is_show : (this.state.user_data.role_user.role_name != "user.basic" ? true : false )},
                {title : 'Terms & Condition', icon : 'fas fa-clipboard-list', nav : '/terms-condition', is_show : true },
                // {title : 'Messages', icon : 'fas fa-comment-alt', nav : '/', is_show : true},
                // {title : 'Terms & Conditions', icon : 'fas fa-file-signature', nav : '/', is_show : true},
            ]
    
            var bottom_menu_arr = [
                {title : 'Help', icon : 'fas fa-question', nav : '/help', is_show : true},
                {title : 'Sign Out', icon : 'fas fa-sign-out-alt', nav : '/signout', is_show : true},
            ]

            this.setState({sidemenu_arr : sidemenu_arr, bottom_menu_arr : bottom_menu_arr})
        }
        else{
            this.$('body').removeClass('no-scroll');
        }
        await this.setState({is_sidemenu : action})
    }

    async signIn(){
        window.location.href = '/auth/login'
    }

    async sidemenu_selected(index, type){
        if(type == 'bottom' && this.state.bottom_menu_arr[index].nav == '/signout'){
            await localStorage.clear()
            window.location.href = '/'
        }
        else{
            window.location.href = (type == 'main') ? this.state.sidemenu_arr[index].nav : this.state.bottom_menu_arr[index].nav
        }
    }
    
    async toProfile(){
        window.location.href = '/profile'
    }

    render(){
        return (
            <>
            <nav className={"sidemenu"+(this.state.is_sidemenu ? ' sidemenu-active' : '')} onClick={()=>this.sideBar(false)}>
                <div className="sidemenu-left">
                    
                    <div className="row m-0">

                        <div className="col-12 p-4 border-bottom-gray">
                            <div className="row m-0 mt-4">

                                <div className="col-auto p-0">
                                    <img src={this.no_user_image} className="img-profile-picture" />
                                </div>
                                <div className="col py-2">
                                    <p className="m-0">Welcome Back</p>
                                    <h5 className="text-primary m-0 text-capitalize">{this.state.user_data.name}</h5>

                                    <button className="btn btn-primary btn-sm mt-3" onClick={()=>this.toProfile()}>View Profile</button>
                                </div>

                            </div>
                        </div>


                        <div className="col-12 p-4">
                            {
                                this.state.sidemenu_arr.map((data, index)=>(
                                    <>
                                    {
                                        data.is_show ?
                                        <div className="p-0 py-2" key={index} onClick={()=>this.sidemenu_selected(index, 'main')}>
                                            <i className={data.icon + " mr-2"}></i>{data.title}
                                        </div> : <></>
                                    }
                                    </>
                                ))
                            }

                        </div>
                        <div className="p-4 sidebar-bottom pb-5 mb-3">
                        {
                            this.state.bottom_menu_arr.map((data,index)=>(
                                <>
                                    {
                                        data.is_show ?
                                        <div className="p-0 py-2" key={index} onClick={()=>this.sidemenu_selected(index, 'bottom')}>
                                            <i className={data.icon + " mr-2"}></i>{data.title}
                                        </div> : <></>
                                    }
                                </>
                            ))
                        }
                        </div>
                    </div>

                </div>
                <div className="sidemenu-right"></div>
            </nav>
            
            <div className="p-4">
                <div className="row m-0">
                    <div className="col p-0">
                        <h4 className="m-0">
                            <span onClick={()=>this.sideBar(true)}>
                            <i className="fa fa-bars text-primary"></i>
                            </span>
                        </h4>
                    </div>
                    <div className="col p-0 text-right">
                        <h4 className="m-0">
                            <span onClick={()=>this.toNotif()}>
                                <i className="fa fa-bell text-primary"></i>
                            </span>
                        </h4>
                        {
                            this.props.count_notif > 0 ?
                            <span className="notif-alert"></span>
                            : <></>
                        }
                    </div>
                </div>
            </div>
            </>
        );
    }
}