import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'
import imageBase from 'image-to-base64'
import Alert from '../../Components/alert'

export default class TopUpReceipt extends Base{
    state = {
        token : '',
        data_arr : [],
        receipt : {image_display : '', image : ''},
        alert : {
            message : '', type : '', is_alert : false
        },
        is_disable : false,
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
    }

    async image_change(event){
        var receipt = {
            image_display : URL.createObjectURL(event.target.files[0]),
            image : await imageBase(URL.createObjectURL(event.target.files[0]))
        }
        await this.setState({alert : {message : '', type : '', is_alert : false}})
        await this.setState({receipt : receipt})
    }

    async save_proof(){
        if(this.state.receipt.image == ''){
            this.alert_show('Proof is empty', 'danger')
        }
        else{
            var data = {}
            data.id = await localStorage.getItem('trader_wallet_id')
            data.image = this.state.receipt
    
            try {
                await this.setState({is_disable : true})
                var response = await this.axios.put(this.url + '/transaction/top-up', data, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : this.state.token
                  },
                });
    
                if(response.data.status == 'success'){
                    this.alert_show('Successfully', 'success')
                    setTimeout(()=>{
                        window.location.href = '/'
                    }, 2000);
                }
                else{
                    await this.setState({is_disable : false})
                    this.alert_show(response.data.message, 'danger')
                }
    
            } 
            catch (e) {
                console.log(e.message)
            }
        }
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Upload Receipt'} show_back={true} />

                    <div className="row m-0">
                        <div className="col-12 p-0 mt-4">
                            {
                                this.state.alert.is_alert ?
                                <Alert type={this.state.alert.type} message={this.state.alert.message} /> : <></>
                            }
                            <form className="row m-0" autoComplete="off">
                                <div className="col-12 p-0">
                                    <p>Proof of Receipt</p>
                                    <button className="btn btn-primary" type='button' onClick={()=> document.getElementById("input_photo").click() }>Upload Proof</button>
                                    <input type="file" accept="image/*" className="d-none" id="input_photo" onChange={(event)=>this.image_change(event)} />
                                    
                                    {
                                        this.state.receipt.image_display != '' ?
                                        <div className="ratio-11 mt-3" style={{backgroundImage : "url(" + this.state.receipt.image_display +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain', width : '75%'}}></div>
                                        : <></>
                                    }
                                </div>

                                <div className="col-12 p-0 mt-3">
                                    <button className="btn btn-primary w-50" type='button' disabled={this.state.is_disable} onClick={()=>this.save_proof()}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>
            </>
        )
    }
}