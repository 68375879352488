import React from 'react';
import Base from '../../Utils/Base';

import HeaderNav from '../../Components/headerNav'

export default class SecurityQuestion extends Base{
    async componentDidMount() {
    }
    
    async backToHome(){
        await localStorage.clear()
        window.location.href = "/"
    }

    render(){
        return (
        <div className="p-4" style={{height : '100vh'}}>
            <HeaderNav title={'Thank You!'} show_back={true} />

            <p className="mt-5">We are <b>successfully</b> send the reset link to your email. </p>
            <p className="mt-3">Please follow the link before it's expired</p>
            
            <div className="pt-5">
                <button className="btn btn-primary w-100" type='button' onClick={()=>this.backToHome()}>Back to Home</button>
            </div>
            
        </div>
        );
    }
}