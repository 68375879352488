import React from 'react';
import Base from '../Utils/Base';

import Alert from '../Components/alert'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

export default class SignUp extends Base{
    state = {
        signup : {
            email : '',
            name : '',
            phone_number : '',
            password : '',
            retype_pass : '',
            security_question : {id : ''},
            answer : '',
        },
        question_arr : [],
        alert : {
            message : '', type : '', is_alert : false
        },
        is_disable : false
    }

    async componentDidMount() {
        await localStorage.clear()
        await this.getSecurityQuestion()
    }

    async getSecurityQuestion(){
        try {
            var response = await this.axios.get(this.url + '/security-question', {
                headers: {
                'Content-Type': 'application/json',
                },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                await this.setState({question_arr : data})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }
    
    async next(){
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if(this.state.signup.email == ''){
            this.alert_show('Email cannot be null', 'danger')
        }
        else if(this.state.signup.name == ''){
            this.alert_show('Name cannot be null', 'danger')
        }
        else if(!this.state.signup.email.match(mailformat)){
            this.alert_show('Email format is wrong', 'danger')
        }
        else if(this.state.signup.phone_number == ''){
            this.alert_show('Phone cannot be null', 'danger')
        }
        else if(this.state.signup.password == ''){
            this.alert_show('Password cannot be null', 'danger')
        }
        else if(this.state.signup.retype_pass == ''){
            this.alert_show('Re-Type Password cannot be null', 'danger')
        }
        else if(this.state.signup.password != this.state.signup.retype_pass){
            this.alert_show(`Password doesn't match`, 'danger')
        }
        else if(this.state.signup.security_question.id == ''){
            this.alert_show(`Choose one security question`, 'danger')
        }
        else if(this.state.signup.answer == ''){
            this.alert_show(`Your answer is empty`, 'danger')
        }
        else{
            await this.setState({is_disable : true})
            var data = this.state.signup
            try {
                var response = await this.axios.post(this.url + '/auth/register', data, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });

                if(response.data.status == 'success'){
                    await localStorage.setItem('token', response.data.token)

                    this.alert_show(`Register Success. Please Wait...`, 'success')

                    setTimeout(()=>{
                        window.location.href = '/auth/referral'
                    }, 2000);
                }
                else{
                    await this.setState({is_disable : false})
                    this.alert_show(response.data.message, 'danger')
                }
            } 
            catch (e) {
                console.log(e.message)
            }
        }
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async changeInput(value, type){
        await this.setState({alert : {message : '', type : '', is_alert : false}})

        var signup_data = this.state.signup
        signup_data[type] = (type == 'security_question') ? {id : value} : value
        await this.setState({signup : signup_data})
    }

    async goBack(){
        window.history.back()
    }

    render(){
        return (
        <div className="p-4" style={{height : '100vh'}}>
            <HeaderNav title={'Sign Up'} show_back={true} />

            <form className="pt-4" autoComplete="off">
            {
                this.state.alert.is_alert ?
                <Alert type={this.state.alert.type} message={this.state.alert.message} /> : <></>
            }

                <div className="input-group mb-3">
                    <input type="email" className="form-control" placeholder="Email" value={this.state.signup.email} onChange={(value)=>this.changeInput(value.target.value, 'email')} />
                </div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Name" value={this.state.signup.name} onChange={(value)=>this.changeInput(value.target.value, 'name')} />
                </div>
                <div className="input-group mb-3">
                    <input type="number" className="form-control" placeholder="Phone Number" value={this.state.signup.phone_number} onChange={(value)=>this.changeInput(value.target.value, 'phone_number')} />
                </div>
                <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Password" value={this.state.signup.password} onChange={(value)=>this.changeInput(value.target.value, 'password')} />
                </div>
                <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Re-Type Password" value={this.state.signup.retype_pass} onChange={(value)=>this.changeInput(value.target.value, 'retype_pass')} />
                </div>
                <div className="input-group mb-3">
                    <select className="custom-select" value={this.state.signup.security_question.id} onChange={(event)=>this.changeInput(event.target.value, 'security_question')}>
                        <option disabled value=''>Choose One</option>
                        {
                            this.state.question_arr.map((data) => 
                                <option value={data.id}>{data.question}</option>
                            )
                        }
                    </select>
                </div>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Your Answer Here" value={this.state.signup.answer} onChange={(value)=>this.changeInput(value.target.value, 'answer')} />
                </div>
                
                <BottomButton buttonAction={()=>this.next()} is_disabled={this.state.is_disable} title={'Next'} />

            </form>
        </div>
        );
    }
}
// export default App;
