import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

import ModalAlert from '../Components/modalAlert'

export default class Membership extends Base{

    state = {
        package_arr : [],
        package_selected : {id : ''},
        upgrade_data : {period : ''},
        trader_data : {},
        modal_alert : {title : '', message : ''},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var upgrade_data = await localStorage.getItem('upgrade_data')
        upgrade_data = JSON.parse(upgrade_data)
        await this.setState({token : token, upgrade_data : upgrade_data})

        await this.get_auth()
        await this.get_package()
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                var trader_data = data.trader[0]
                await this.setState({trader_data : trader_data})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.href = '/'
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async get_package(){
        try {
            var response = await this.axios.get(this.url + '/membership', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                for(var x in data){
                    data[x].is_selected = false
                    var setting = data[x].setting
                    for(var y in setting){
                        if(setting[y].period == this.state.upgrade_data.period){
                            data[x].detail = setting[y]
                        }
                    }
                }
                await this.setState({package_arr : data})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async selectPackage(index){
        var package_data = this.state.package_arr
        for(var x in package_data){
            package_data[x].is_selected = false
        }
        package_data[index].is_selected = true
        await this.setState({package_arr : package_data, package_selected : package_data[index]})

    }

    async toNext(){
        if(this.state.package_selected.id == ''){
            var modal_alert = {
                title : 'Alert',
                message : 'Select a package.'
            }
            await this.setState({modal_alert : modal_alert})
            this.$('#modalAlert').modal('show')
        }
        else{
            var upgrade_data = this.state.upgrade_data
            upgrade_data.membership = this.state.package_selected
            await localStorage.setItem('upgrade_data', JSON.stringify(upgrade_data))
            window.location.href = '/package/upgrade-plan/form'
    
            // if(this.state.trader_data.metadata == null){
            // }
            // else{
            //     window.location.href = '/package/upgrade-plan/confirmation'
            // }
        }
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Upgrade Plan'} show_back={true} />
                    
                    <div className="row m-0 my-4 pb-5">

                    <div className={"col-12 p-3 mb-3 border-left-contain bg-dark"}>
                        <div className="custom-control custom-radio">
                            <input type="radio" id={"radioPeriod"} name="periodPackage" value={this.state.upgrade_data.period} className="custom-control-input" checked />
                            <label className="custom-control-label" htmlFor={"radioPeriod"}>{this.state.upgrade_data.period} months</label>
                        </div>
                    </div>

                        {
                            this.state.package_arr.map((data, index)=>(
                                <div className={"col-12 p-3 mb-3 border-left-contain " + (data.is_selected ? 'bg-secondary' : 'bg-dark ')} onClick={()=>this.selectPackage(index)}>
                                    <h4 className="title m-0">{data.name}//</h4>

                                    <div>
                                    <h5 className="m-0 mt-2">{this.euro_currency} {parseFloat(data.deposit).toLocaleString(this.currency_format)}</h5>
                                    <p>Minimum Investment</p>
                                    </div>

                                    <div>
                                    <h5 className="m-0 mt-2">{data.detail.guaranteed_roi}%</h5>
                                    <p>Guarantee ROI</p>
                                    </div>

                                    <div>
                                    <h5 className="m-0 mt-2">{data.detail.additional_profit_sharing}%</h5>
                                    <p>Profit Share</p>
                                    </div>
                                </div>
                            ))
                        }
                        

                    </div>

                    <BottomButton buttonAction={()=>this.toNext()} is_disabled={false} title={'Next'} />

                </div>
            </div>

            <ModalAlert title={this.state.modal_alert.title} message={this.state.modal_alert.message} />

            </>
        )
    }
}