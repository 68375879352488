import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import HeaderNav from '../Components/headerNav'

export default class NewMessage extends Base{
    async joinNow(){
        window.location.href = '/auth/login'
    }

    async componentDidMount(){
        var token = '123'
        await this.setState({token : token})
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'New Message'} show_back={true} />
                    
                    <div className="row m-0 mt-3">
                        <div className="col-12 p-0">
                            <form className="pt-4" autoComplete="off">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text-search" id="basic-addon1"><i class="fas fa-search"></i></span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                            </form>
                        </div>

                        <div className="col-12 p-0 py-3 border-bottom-gray">
                            <div className="row m-0">

                                <div className="col-auto p-0">
                                    <img src={this.no_user_image} className="img-profile-picture" />
                                </div>
                                <div className="col p-0 pl-3 align-self-center">
                                    <h5 className="text-primary m-0">Name</h5>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </>
        )
    }
}