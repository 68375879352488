import React from 'react';
import Base from '../Utils/Base';

export default class Alert extends Base{
  render(){
    return (
        <div className={"rounded alert alert-"+this.props.type} role="alert">
            {this.props.message}
        </div>
    );
  }
}
// export default App;
