import React from 'react';
import Base from '../Utils/Base';

import HeaderNav from '../Components/headerNav'
import Alert from '../Components/alert'
import ModalAlert from '../Components/modalAlert'

export default class Login extends Base{
    state = {
        login : {email : '', password : ''},
        alert : {
            message : '', type : '', is_alert : false
        },
        alertMessage : '',
        is_disable : false,
    }

    async componentDidMount() {
        await localStorage.clear()
    }
    
    async login(){
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        
        if(this.state.login.email == ''){
            this.alert_show('Email / Phone Number cannot be null', 'danger')
        }
        else if(!this.state.login.email.match(mailformat)){
            this.alert_show('Email format is wrong', 'danger')
        }
        else if(this.state.login.password == ''){
            this.alert_show('Password cannot be null', 'danger')
        }
        else{
            var data = this.state.login

            try {
                await this.setState({is_disable : true})
                var response = await this.axios.post(this.url + '/auth/login', data, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });

                if(response.data.status == 'success'){
                    this.alert_show('Login Success. Please Wait...', 'success')
                    await localStorage.setItem('token', response.data.token)

                    setTimeout(()=>{
                        window.location.href = '/'
                    }, 2000);
                }
                else{
                    await this.setState({is_disable : false})
                    this.alert_show(response.data.message, 'danger')
                }

            } 
            catch (e) {
                await this.setState({is_disable : false})
                await this.setState({alertMessage : e.message})
                this.$('modalAlert').modal('show')
            }
        }
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async changeInput(value, type){
        await this.setState({alert : {message : '', type : '', is_alert : false}})

        var login_data = this.state.login
        login_data[type] = value
        await this.setState({login : login_data})
    }

    render(){
        return (
        <div className="login-page p-4" style={{height : '100vh'}}>
            <HeaderNav title={'Sign In'} show_back={false} />

            
            <form className="pt-4" autoComplete="off">
                {
                    this.state.alert.is_alert ?
                    <Alert type={this.state.alert.type} message={this.state.alert.message} /> : <></>
                }
                <div className="input-group mb-2">
                    <input type="email" className="form-control" placeholder="Email" value={this.state.login.email} onChange={(value)=>this.changeInput(value.target.value, 'email')} />
                </div>
                <div className="input-group mb-2">
                    <input type="password" className="form-control" placeholder="Password" value={this.state.login.password} onChange={(value)=>this.changeInput(value.target.value, 'password')} />
                </div>
                <a href="/auth/forget-password" className="link-underline">Forget Password?</a>

                <div className="pt-5">
                    <button className="btn btn-primary w-100" disabled={this.state.is_disable} type='button' onClick={()=>this.login()}>Submit</button>
                </div>

                <div className="pt-5">
                    <p className="m-0 mb-2">Don't have an Account?</p>
                    <a href="/auth/signup" className="link-underline">Sign Up here</a>
                </div>
            </form>

            <ModalAlert title={'Alert'} message={this.state.alertMessage} />

        </div>
        );
    }
}
// export default App;
