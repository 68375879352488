import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import HeaderNav from '../Components/headerNav'

export default class Help extends Base{
    state = {
        help_arr : [
            // {id : 0, title : 'Make a Deposit', icon : 'fas fa-money-bill-wave', type : 'make-deposit'},
            // {id : 1, title : 'Make a Withdraw', icon : 'fas fa-money-check', type : 'make-withdraw'}
            {title : 'Binance - cara daftar', pdf_name : 'Binance_CARA_DAFTAR.pdf'},
            {title : 'Binance - cara KYC', pdf_name : 'Binance_CARA_KYC.pdf'},
            {title : 'Binance - cara deposit', pdf_name : 'Binance_CARA_DEPOSIT.pdf'},
            {title : 'Binance - cara membeli USDT', pdf_name : 'Binance_CARA_MEMBELI_USDT.pdf'},
            {title : 'Binance  - cara withdraw wallet', pdf_name : 'Binance_CARA_WITHDRAW_KE_EXTERNAL_WALLET.pdf'},
            {title : 'Tokocrypto - cara daftar', pdf_name : 'Tokocrypto_CARA_DAFTAR.pdf'},
            {title : 'Tokocrypto - cara deposit', pdf_name : 'Tokocrypto_CARA_DEPOSIT.pdf'},
            {title : 'Tokocrypto - cara withdraw wallet', pdf_name : 'Tokocrypto_CARA_WITHDRAW_KE_EXTERNAL_WALLET.pdf'},
            {title : 'Tokocrypto - cara membeli USDT', pdf_name : 'Tokocrypto_CARA_MEMBELI_USDT.pdf'},
            {title : 'Tokocrypto - cara KYC', pdf_name : 'Tokocrypto_CARA_KYC.pdf'},
        ]
    }

    async toDetail(index){
        if(this.state.help_arr[index].pdf_name != ''){
            window.open(require('../asset/pdf/' + this.state.help_arr[index].pdf_name))
        }
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Help'} show_back={true} />
                    
                    <div className="row mt-3">
                    {
                        this.state.help_arr.map((data, index)=>(
                            <div className="col-12 py-2 transaction-list" onClick={()=>this.toDetail(index)}>
                                <u>{data.title}</u>
                            </div>
                        ))
                    }
                    </div>

                </div>
            </div>
            </>
        )
    }
}