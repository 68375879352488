import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

export default class ReferralDashboard extends Base{
    state = {
        token : '',
        user_data : {referral_code : {}, referral_code_usement : []},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

        await this.get_auth()
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                await this.setState({user_data : data})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.href = '/'
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    render(){
        return (
            <>
            <div className="p-4">

                <HeaderNav title={'Referral Dashboard'} show_back={true} />

                <div className="row m-0 mt-4 pb-5 mb-5">
                    <div className="col-12 p-0">
                        <p className="m-0"><b>Your Referral Code : </b></p>
                        <h4 className="text-primary">{this.state.user_data.referral_code.referral_code}</h4>
                    </div>

                    <div className="col-12 p-0 mt-3">
                        <p className="m-0"><b>Your Referral Code used by : </b></p>
                        
                        <div className="row m-0">
                            {
                                this.state.user_data.referral_code_usement.map((data, index)=>(
                                    <div className="col-12 p-2 transaction-list" key={index}>
                                        <p className="m-0 text-capitalize d-flex h-100 align-items-center"><b><i className="far fa-user"></i> {data.name}</b></p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>

            </div>
            </>
        )
    }
}