import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

import Alert from '../Components/alert'
import imageBase from 'image-to-base64'

export default class UpgradePlanForm extends Base{

    state = {
        token : '',
        user_data : {name : '', phone_number : ''},
        identity_type_data : [],
        alert : {
            message : '', type : '', is_alert : false
        },
        upgrade_data : {identity_type : {id : '', type : ''}, identity_number : '', image : {image : '', image_display : ''}},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var upgrade_data = await localStorage.getItem('upgrade_data')
        upgrade_data = JSON.parse(upgrade_data)
        upgrade_data.identity_type = {id : '', type : ''}
        upgrade_data.identity_number = ''
        upgrade_data.image = {image : '', image_display : ''}
        await this.setState({token : token, upgrade_data : upgrade_data})

        await this.get_auth()
        await this.get_identity_type()
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                console.log(data)
                await this.setState({user_data : data})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.href = '/'
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async get_identity_type(){
        try {
            var response = await this.axios.get(this.url + '/identity-type', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                await this.setState({identity_type_data : data})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async image_change(event){
        var upgrade_data = this.state.upgrade_data
        
        upgrade_data.image = {
            image_display : URL.createObjectURL(event.target.files[0]),
            original_rotation : 0,
            image : await imageBase(URL.createObjectURL(event.target.files[0])),
        }
        await this.setState({upgrade_data : upgrade_data})        
    }

    async toNext(){
        if(this.state.upgrade_data.identity_type.id == ''){
            this.alert_show('Identity Type is empty', 'danger')
        }
        else if(this.state.upgrade_data.identity_number == ''){
            this.alert_show('ID Number is empty', 'danger')
        }
        else{
            await localStorage.setItem('upgrade_data', JSON.stringify(this.state.upgrade_data))
            window.location.href = '/package/upgrade-plan/confirmation'
        }
    }

    async alert_show(message, type){
        window.scrollTo(0, 0)
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async changeInput(value, type){
        await this.setState({alert : {message : '', type : '', is_alert : false}})
        var upgrade_data = this.state.upgrade_data
        upgrade_data[type] = value
        if(type == 'identity_type'){
            upgrade_data[type] = JSON.parse(value)
        }

        await this.setState({upgrade_data : upgrade_data})
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Upgrade Form'} show_back={true} />
                    
                        
                    <form className="py-4 mb-5" autoComplete="off">
                        {
                            this.state.alert.is_alert ?
                            <Alert type="danger" message={this.state.alert.message} /> : <></>
                        }
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Full Name" readOnly value={this.state.user_data.name} onChange={()=>console.log('123')} />
                        </div>
                        <div className="input-group mb-3">
                            <input type="number" className="form-control" placeholder="Mobile Number" readOnly value={this.state.user_data.phone_number} />
                        </div>

                        <h5 className="mt-4 mb-3">Identity Information</h5>
                        <div className="input-group mb-3">
                            <select className="custom-select" value={JSON.stringify(this.state.upgrade_data.identity_type)} onChange={(event)=>this.changeInput(event.target.value, 'identity_type')}>
                                <option disabled value={JSON.stringify({id : '', type : ''})}>ID Type</option>
                                {
                                    this.state.identity_type_data.map((data, index)=>(
                                        <option key={index} value={JSON.stringify(data)}>{data.type}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="input-group mb-3">
                            <input type="number" className="form-control" placeholder="ID Number" value={this.state.upgrade_data.identity_number} onChange={(value)=>this.changeInput(value.target.value, 'identity_number')} />
                        </div>


                        <h5 className="mt-4 mb-3">Proof of Identity</h5>
                        <div className="input-group mb-3">
                            <button className="btn btn-primary" type='button' onClick={()=> document.getElementById("input_photo").click() }>Upload Proof</button>
                            <input type="file" accept="image/*" className="d-none" id="input_photo" onChange={(event)=>this.image_change(event)} />
                            {
                                this.state.upgrade_data.image.image_display != '' ?
                                <div className="ratio-11 mt-3" style={{backgroundImage : "url(" + this.state.upgrade_data.image.image_display +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain', width : '75%'}}></div>
                                : <></>
                            }

                        </div>
                        
                    </form>

                    <BottomButton buttonAction={()=>this.toNext()} is_disabled={false} title={'Next'} />

                </div>
            </div>
            </>
        )
    }
}