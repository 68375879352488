import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

import Alert from '../Components/alert'

export default class UpgradePlan extends Base{

    state = {
        package_arr : [],
        period_arr : ['6', '12', '24'],
        selected_period : '',
        alert : {
            message : '', type : '', is_alert : false
        },
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
    }

    async toNext(){
        if(this.state.selected_period == ''){
            this.alert_show('Select period', 'danger')
        }
        else{
            var upgrade_data = {
                period : this.state.selected_period
            }
            await localStorage.setItem('upgrade_data', JSON.stringify(upgrade_data))
            window.location.href = '/package/upgrade-plan/detail'
        }
    }

    async selectPeriod(val){
        await this.setState({alert : {is_alert : false}})
        await this.setState({selected_period : val})
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Upgrade Plan'} show_back={true} />
                    <p className="mt-4">Pick a Time Period</p>
                    
                    <div className="row m-0">
                        <div className="col-12 p-0">

                        <form autoComplete="off">
                            {
                                this.state.alert.is_alert ?
                                <Alert type={this.state.alert.type} message={this.state.alert.message} /> : <></>
                            }                            
                            {
                                this.state.period_arr.map((data, index)=>(
                                    <div className="custom-control custom-radio mb-2" key={index}>
                                        <input type="radio" id={"radioPeriod"+data} name="periodPackage" value={data} className="custom-control-input" checked={this.state.selected_period == data} onChange={()=>this.selectPeriod(data)} />
                                        <label className="custom-control-label" htmlFor={"radioPeriod"+data}>{data} months</label>
                                    </div>
                                ))
                            }
                        </form>
                        
                        </div>

                    </div>

                    <BottomButton buttonAction={()=>this.toNext()} is_disabled={false} title={'Next'} />

                </div>
            </div>
            </>
        )
    }
}