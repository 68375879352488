import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import BottomButton from '../Components/bottomButton'

export default class Profile extends Base{
    state = {
        token : '',
        landing_contact_us : {fullname : '', email : '', message : ''},
        user_data : {role_user : {role_name : ''}, name : '', email : '', phone_number : '', trader_data : {}},
        identity_type_data : [],
        identity_info : {identity_type : {}, identity_number : ''},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

        await this.get_identity_type()
        await this.get_auth()
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                data.role_user = data.role[0].role
                data.trader_data = data.trader[0]

                console.log(data)

                
                var identity_info = this.state.identity_info
                if(data.trader_data.metadata != null){
                    var metadata = JSON.parse(data.trader_data.metadata)
                    identity_info.identity_type = data.trader_data.identity_type
                    identity_info.identity_number = metadata.identity.id_number
                    // console.log(JSON.parse(data.trader_data.metadata).identity)
                }
                console.log(identity_info)
                
                await this.setState({user_data : data, identity_info : identity_info})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.href = '/'
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async changeInput(value, type){
        var data = this.state.user_data
        data[type] = value
        await this.setState({user_data : data})
    }

    async changeIDInfo(value, type){
        var data = this.state.identity_info
        data[type] = value

        if(type == 'identity_type'){
            data[type] = JSON.parse(value)
        }
        await this.setState({identity_info : data})
    }

    async get_identity_type(){
        try {
            var response = await this.axios.get(this.url + '/identity-type', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                await this.setState({identity_type_data : data})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async saveProfile(){
        var data = this.state.user_data
        try {
            var response = await this.axios.put(this.url + '/auth/change-profile', data, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                console.log(response.data)
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.href = '/'
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    render(){
        return (
            <>
            <div className="p-4">

                <HeaderNav title={'Profile'} show_back={true} />

                <form className="pt-4" autoComplete="off">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Name" value={this.state.user_data.name} onChange={(value)=>this.changeInput(value.target.value, 'name')} />
                    </div>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Email" value={this.state.user_data.email} readOnly />
                    </div>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Mobile Phone" value={this.state.user_data.phone_number} onChange={(value)=>this.changeInput(value.target.value, 'phone_number')} />
                    </div>

                    {/* {
                        this.state.user_data.role_user.role_name != "user.basic" ? 
                        <>
                        <div className="input-group mb-3">
                            <select className="custom-select" value={JSON.stringify(this.state.identity_info.identity_type)} onChange={(event)=>this.changeIDInfo(event.target.value, 'identity_type')}>
                                <option disabled value={JSON.stringify({id : '', type : ''})}>ID Type</option>
                                {
                                    this.state.identity_type_data.map((data, index)=>(
                                        <option key={index} value={JSON.stringify(data)}>{data.type}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="ID Number" value={this.state.identity_info.identity_number} onChange={(value)=>this.changeIDInfo(value.target.value, 'identity_number')} />
                        </div>
                        </>
                        : <></>
                    } */}

                    <a href="/profile/change-password" className="link-underline">Change Password</a>

                    <BottomButton buttonAction={()=>this.saveProfile()} is_disabled={false} title={'Save'} />

                </form>
            </div>
            </>
        )
    }
}