import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import ListDetail from '../Components/list_detail'
import BottomButton from '../Components/bottomButton'

export default class TraderPackageDetail extends Base{

    state = {
        token : '',
        package_data : {membership : {setting : []}, setting_data : {id : '', guaranteed_roi : 0, estimated_profit_sharing : 0}},
        detail_data_arr : [],
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        // var package_data = await localStorage.getItem('packageDetail')
        // package_data = JSON.parse(package_data)
        await this.setState({token : token})

        await this.get_data()

        console.log(this.state.package_data)

        var detail_data_arr = [
            {title : 'Package ID', value : this.state.package_data.id},
            {title : 'Status', value : this.state.package_data.status},
            {title : 'Start Date', value : this.moment(this.state.package_data.begin_date).format('DD-MM-YYYY HH:mm')},
        ]
        await this.setState({detail_data_arr : detail_data_arr})

    }

    async get_data(){
        try {
            var response = await this.axios.get(this.url + '/trader-package?id=' + this.props.match.params.id, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                var membership_setting = data.membership.setting
                data.setting_data = {}
                for(var x in membership_setting){
                    if(membership_setting[x].period == data.period){
                        data.setting_data = membership_setting[x]
                    }
                }

                await this.setState({package_data : data})

            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                window.location.href = '/'
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async endPackage(){
        await localStorage.setItem('packageDetail', JSON.stringify(this.state.package_data))
        window.location.href = '/package/end'
    }

    render(){
        return (
            <>

            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Detail Package'} show_back={true} />

                    <div className="row m-0 pb-5 mb-5">
                        <div className="col-12 p-0 mt-4">
                            
                            <div className="row m-0">
                            {
                                this.state.detail_data_arr.map((data, index)=>(
                                    <ListDetail key={index} title={data.title} value={data.value} />
                                ))
                            }

                            <div className={"col-12 p-3 mb-3 border-left-contain bg-dark"}>
                                    <h4 className="title m-0">{this.state.package_data.membership.name}//</h4>

                                    <div>
                                    <h5 className="m-0 mt-2">{this.euro_currency} {parseFloat(this.state.package_data.amount).toLocaleString(this.currency_format)}</h5>
                                    <p>Investment</p>
                                    </div>

                                    <div>
                                    <h5 className="m-0 mt-2">{this.state.package_data.setting_data.guaranteed_roi}%</h5>
                                    <p>Guarantee ROI</p>
                                    </div>

                                    <div>
                                    <h5 className="m-0 mt-2">{this.state.package_data.setting_data.estimated_profit_sharing}%</h5>
                                    <p>Profit Share</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={"col-12 p-3 border-left-contain bg-dark"}>
                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        <div className="row m-0">
                                            <div className="col p-0">
                                                <p className="m-0">Investment</p>
                                            </div>
                                            <div className="col p-0 text-right">
                                                <p className="m-0"><b>{this.euro_currency} {parseFloat(this.state.package_data.amount).toLocaleString(this.currency_format)}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-12 p-0 text-right">
                                <a href="#!" className="text-white"><small><u>View History Investment</u></small></a>
                            </div> */}

                            <BottomButton buttonAction={()=>this.endPackage()} is_disabled={this.state.is_disable} title={'End Package'} />

                        </div>
                    </div>

                </div>
            </div>
            </>
        )
    }
}