import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import Alert from '../Components/alert'
import { data } from 'jquery'

export default class PackageList extends Base{

    render(){
        return (
            <>
            <div className="col-12 p-3 bg-dark border-left-contain mb-3" onClick={()=>this.props.toDetailPackage(this.props.package_data.id)}>
                <div className="row m-0">
                    <div className="col p-0">
                        <h5 className="title m-0">{this.props.package_data.membership.name}//</h5>
                    </div>
                    <div className="col-auto text-right p-0">
                        <small className="m-0 text-capitalize">{this.props.package_data.status}</small>
                    </div>
                </div>
                <p className="m-0">{this.props.package_data.period} Months</p>
                <h4 className="m-0 mt-3">{this.euro_currency} {parseFloat(this.props.package_data.amount).toLocaleString(this.currency_format)}</h4>
            </div>
            </>
        )
    }
}