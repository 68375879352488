import React from 'react';
import Base from '../Utils/Base';

export default class BottomButton extends Base{

    render(){
        return (
            <div className="fixed-bottom p-4 bg-black">
                <button className="btn btn-primary w-100" disabled={this.props.is_disabled == null || !this.props.is_disabled ? false : true} type='button' onClick={()=>this.props.buttonAction()}>{this.props.title}</button>
            </div>
        );
    }
}
