import React from 'react';
import Base from '../../Utils/Base';

import HeaderNav from '../../Components/headerNav'

import Alert from '../../Components/alert'
import ModalAlert from '../../Components/modalAlert'

export default class SecurityQuestion extends Base{
    state = {
        answer : '',
        alertMessage : '',
        alert : {
            message : '', type : '', is_alert : false
        },
        modal_alert : {title : '', message : ''},
        security_question : '',
        email : '',
        is_disable : false,
    }

    async componentDidMount() {
        var email = await localStorage.getItem('forgotPass_email')
        await this.setState({email : email})
        await this.get_security_question()
    }

    async get_security_question(){
        try {
            var response = await this.axios.get(this.url + '/auth/security-question?email=' + this.state.email, {
                email : this.state.email
            }, {
              headers: {
                'Content-Type': 'application/json',
              },
            });

            if(response.data.status == 'success'){
                await this.setState({security_question : response.data.security_question.question})
            }
            else{
                var modal_alert = {title : 'Alert', message : response.data.message}
                await this.setState({modal_alert : modal_alert})
                this.$('#modalAlert').modal('show')

                this.$('#modalAlert').on('hidden.bs.modal', function (e) {
                    window.location.reload()
                    window.history.back()
                })
            }

        } 
        catch (e) {
            await this.setState({is_disable : false})
            var modal_alert = {title : 'Alert', message : e.message}
            await this.setState({modal_alert : modal_alert})
            this.$('#modalAlert').modal('show')
        }
    }
    
    async next(){
        if(this.state.answer == ''){
            this.alert_show('Input your answer', 'danger')
        }
        else{
            try {
                await this.setState({is_disable : true})
                var response = await this.axios.post(this.url + '/auth/forget-password', {
                    email : this.state.email,
                    answer : this.state.answer
                }, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
    
                if(response.data.status == 'success'){
                    setTimeout(()=>{
                        window.location.href = '/auth/forget-password/success'
                    }, 2000);
                }
                else{
                    await this.setState({is_disable : false})
                    this.alert_show(response.data.message, 'danger')
                }
    
            } 
            catch (e) {
                await this.setState({is_disable : false})
                var modal_alert = {title : 'Alert', message : e.message}
                await this.setState({modal_alert : modal_alert})
                this.$('#modalAlert').modal('show')
            }
        }
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async changeInput(value){
        await this.setState({alert : {message : '', type : '', is_alert : false}})
        await this.setState({answer : value, is_disable : false})
    }

    render(){
        return (
        <div className="p-4" style={{height : '100vh'}}>
            <HeaderNav title={'Security Question'} show_back={true} />

            <p className="mt-5">
            {this.state.security_question}
            </p>
            
            <form className="pt-4" autoComplete="off">
            {
                this.state.alert.is_alert ?
                <Alert type="danger" message={this.state.alert.message} /> : <></>
            }
                <div className="input-group mb-4">
                    <input type="text" className="form-control form-control-lg" placeholder="Your Answer" value={this.state.answer} onChange={(value)=>this.changeInput(value.target.value)} />
                </div>

                <div className="pt-5">
                    <button className="btn btn-primary w-100" type='button' disabled={this.state.is_disable} onClick={()=>this.next()}>Next</button>
                </div>

            </form>

            <ModalAlert title={this.state.modal_alert.title} message={this.state.modal_alert.message} />
        </div>
        );
    }
}
// export default App;
