import React from 'react';
import Base from '../Utils/Base';

import ModalAlert from '../Components/modalAlert'
import Alert from '../Components/alert'

import HeaderNav from '../Components/headerNav'

export default class OTP extends Base{
    inputs = {}
    state = {
        otp : ['', '', '', '', ''],
        alert : {
            message : '', type : '', is_alert : false
        },
        token : '',
        otp_code : '',
        is_disable : false,
        alertMessage : '',
    }

    async componentDidMount() {
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

        // await this.get_otp()
    }

    async get_otp(){
        try {
            var response = await this.axios.get(this.url + '/auth/request-otp', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token,
              },
            });

            if(response.data.status == 'success'){
                var otp = this.state.otp
                for(var x in otp){
                    otp[x] = ''
                }
                this.inputs[0].focus()
                await this.setState({otp : otp})
            }
            else{
                await this.setState({is_disable : false})
                this.alert_show(response.data.message, 'danger')
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }
    
    async next(){
        var otp = this.state.otp
        var otp_input = ''
        for(var x in otp){
            otp_input += otp[x]
        }
        try {
            await this.setState({is_disable : true})
            var response = await this.axios.post(this.url + '/auth/verify-phone', {
                otp_code: otp_input
            }, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token,
              },
            });

            if(response.data.status == 'success'){
                this.alert_show('OTP Code valid. Please Wait...', 'success')
                setTimeout(()=>{
                    window.location.href = '/'
                }, 2000);
            }
            else{
                await this.setState({is_disable : false})
                this.alert_show(response.data.message, 'danger')
            }
        } 
        catch (e) {
            await this.setState({is_disable : false})
            await this.setState({alertMessage : e.message})
            this.$('modalAlert').modal('show')
        }

        
        // if(otp_input == this.state.otp_code){
        //     this.alert_show('OTP Code valid. Please Wait...', 'success')
        //     setTimeout(()=>{
        //         window.location.href = '/'
        //     }, 2000);
        // }
        // else{
        //     this.alert_show('INVALID OTP Code', 'danger')
        // }
        
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async changeInput(value){
        await this.setState({alert : {message : '', type : '', is_alert : false}})

        await this.setState({referral_code : value})
    }

    async changeInput(value, index){
        if(index != this.state.otp.length - 1 && value != ''){
            this.inputs['input'+(index+1)].focus()
        }

        if(index != 0){
            if(value == ''){
                this.inputs['input'+(index-1)].focus()
            }
        }

        var otp = this.state.otp
        otp[index] = value
        await this.setState({otp : otp})

        if(index+1 == otp.length){
            this.next()
        }
    }

    render(){
        var handleFocus = (event) => event.target.select();

        return (
        <div className="p-4" style={{height : '100vh'}}>
            <HeaderNav title={'OTP'} show_back={true} />

            <p className="m-0 mt-4">We send you the OTP code to your phone number, if you don't receive any code in a minute, you can</p>
            <a href="#!" className="link-underline" onClick={()=>this.get_otp()}>Resend code</a>

            <form className="pt-4" autoComplete="off">
            {
                this.state.alert.is_alert ?
                <Alert type={this.state.alert.type} message={this.state.alert.message} /> : <></>
            }
                <div className="row">
                    {
                        this.state.otp.map((data, index)=>(
                            <div className="input-group mb-4 col p-2">
                                <input type="number" className="form-control form-control-lg font-weight-bold otp-input text-center" value={this.state.otp.index} onChange={(value)=>this.changeInput(value.target.value, index)} ref={(input) => { this.inputs['input'+index] = input }} maxLength={1} onFocus={handleFocus} />
                            </div>
                        ))
                    }

                </div>
                
                <div className="py-4">
                    <button className="btn btn-primary w-100" type='button' disabled={this.state.is_disable} onClick={()=>this.next()}>Submit</button>
                </div>
            </form>

            <ModalAlert title={'Alert'} message={this.state.alertMessage} />
        </div>
        );
    }
}
// export default App;
