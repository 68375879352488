import React from 'react';
import Base from '../Utils/Base';
import {Route, BrowserRouter} from 'react-router-dom'

import Home from '../Index/index';
import Notification from '../Notification'
import TermsCondition from '../TermsCondition'

import Profile from '../Profile'
import ChangePassword from '../Profile/ChangePassword';

import Help from '../Help'
import HelpDetail from '../Help/detail'

import HistoryTransaction from '../HistoryTransaction'
import TransactionDetail from '../HistoryTransaction/detail'

import Message from '../Message'
import NewMessage from '../Message/newMessage'
import ChatRoom from '../Message/chatRoom'

import Cash from '../Cash'
import TopUp from '../Cash/TopUp';
import TopUpInvoice from '../Cash/TopUp/invoice';
import TopUpPayment from '../Cash/TopUp/paymentMethod';
import TopUpSubmit from '../Cash/TopUp/submit';
import TopUpReceipt from '../Cash/TopUp/uploadReceipt';
import TopUpOTP from '../Cash/TopUp/otp';

import WithdrawPackage from '../Cash/Withdraw/pickPackage'
import WithdrawAmount from '../Cash/Withdraw/withdrawAmount';
import PaymentReceived from '../Cash/Withdraw/paymentReceived';
import WithdrwaInvoice from '../Cash/Withdraw/invoice'
import WithdrawSubmitted from '../Cash/Withdraw/submitted';

import Package from '../Package'
import UpgradePlan from '../Package/upgradePlan'
import Membership from '../Package/membership';
import UpgradePlanForm from '../Package/form';
import PackageSuccess from '../Package/success';
import PackagePayment from '../Package/payment';
import ConfirmationPackage from '../Package/confirmationPackage';
import PackageList from '../Package/listPackage';
import TraderPackageDetail from '../Package/traderPackageDetail';
import EndPackage from '../Package/endPackage';
import EndPackageSubmit from '../Package/endPackageSubmit';

import Investment from '../Investment';
import ReferralDashboard from '../ReferralDashboard';

export default class BaseLayout extends Base{
    render(){
      return (
          <BrowserRouter>
            <Route exact path={"/"} component={Home} />

            <Route exact path={"/notification"} component={Notification} />

            <Route exact path={"/package"} component={Package} />
            <Route exact path={"/package/trader/:id"} component={TraderPackageDetail} />
            <Route exact path={"/package/user/list"} component={PackageList} />
            <Route exact path={"/package/upgrade-plan"} component={UpgradePlan} />
            <Route exact path={"/package/upgrade-plan/detail"} component={Membership} />
            <Route exact path={"/package/upgrade-plan/form"} component={UpgradePlanForm} />
            <Route exact path={"/package/upgrade-plan/payment"} component={PackagePayment} />
            <Route exact path={"/package/upgrade-plan/confirmation"} component={ConfirmationPackage} />
            <Route exact path={"/package/end"} component={EndPackage} />
            <Route exact path={"/package/end/success"} component={EndPackageSubmit} />

            <Route exact path={"/package/success"} component={PackageSuccess} />

            <Route exact path={"/profile"} component={Profile} />
            <Route exact path={"/profile/change-password"} component={ChangePassword} />

            <Route exact path={"/help"} component={Help} />
            <Route exact path={"/help/:type"} component={HelpDetail} />

            <Route exact path={"/history-transaction"} component={HistoryTransaction} />
            <Route exact path={"/history-transaction/detail/:id"} component={TransactionDetail} />

            <Route exact path={"/cash"} component={Cash} />
            <Route exact path={"/cash/top-up"} component={TopUp} />
            <Route exact path={"/cash/top-up/invoice"} component={TopUpInvoice} />
            <Route exact path={"/cash/top-up/payment-method"} component={TopUpPayment} />
            <Route exact path={"/cash/top-up/submit"} component={TopUpSubmit} />
            <Route exact path={"/cash/top-up/upload-receipt"} component={TopUpReceipt} />
            {/* <Route exact path={"/cash/top-up/otp"} component={TopUpOTP} /> */}

            <Route exact path={"/cash/withdraw"} component={WithdrawAmount} />
            <Route exact path={"/cash/withdraw/pick-package"} component={WithdrawPackage} />
            <Route exact path={"/cash/withdraw/payment-received"} component={PaymentReceived} />
            <Route exact path={"/cash/withdraw/invoice"} component={WithdrwaInvoice} />
            <Route exact path={"/cash/withdraw/submitted"} component={WithdrawSubmitted} />

            <Route exact path={"/message"} component={Message} />
            <Route exact path={"/message/new-message"} component={NewMessage} />
            <Route exact path={"/message/chat/:user_id"} component={ChatRoom} />

            <Route exact path={"/investment"} component={Investment} />

            <Route exact path={"/terms-condition"} component={TermsCondition} />

            <Route exact path={"/referral-dashboard"} component={ReferralDashboard} />

          </BrowserRouter>
      )
    }
}