import React from 'react'
import Base from '../Utils/Base';
import Router from '../Router/auth_router'

export default class BaseAuth extends Base {
    render(){
        return (
            <Router />
        )
    }
}