import React from 'react';
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'

export default class TermsCondition extends Base{

    state = {
        terms_condition : [],
    }

    async componentDidMount(){
        var terms_condition = [
            {title : 'VUE99 adalah Sole Distributor dari ARK36, Fully Licensed Fund, terdaftar di Uni Eropa dan diawasi oleh CYSEC (Cyprus Securities and Exchange Commision).', link : '', sub : []},
            {title : 'VUE99 tidak menerima dan mengelola segala dana yang diterima. Segala bentuk penempatan dan investasi yang dilakukan ditujukan ke destinasi wallet', link : '', sub : []},
            {title : 'VUE99 merupakan dana kolektif dimana terkait dengan minimal penempatan dana di ARK36 yaitu sebesar EUR120,000 sehingga keseluruhan penempatan dana kolektif VUE99 akan ditempatkan di ARK36 Cyprus dalam account VUE99 Ltd. selaku Sole Distributor dari ARK36 di ASIA PASIFIK.', link : '', sub : []},
            {title : 'ARK36  memberlakukan TRADING WINDOW mulai dari tanggal 1 – Akhir bulanyang mana penempatan dana harus ditempatkan selambat – lambatnya tanggal 21 bulan sebelumnya.', link : '', sub : []},
            {title : 'Contoh transaksi:', link : '', sub : [
                {point : 'a.', title : 'TOP UP dilakukan pada tanggal 20 Juni'},
                {point : 'b.', title : 'ADD PACKAGE dilakukan pada tanggal 21 Juni'},
                {point : 'c.', title : 'Maka, user tersebut akan mulai mendapatkan return dari kegiatan trading ARK36 mulai tanggal 1 Juli.'},
            ]},
            {title : 'Mata uang yang digunakan di seluruh penempatan investasi adalah EURO.', link : '', sub : []},
            {title : 'Jenis koin yang digunakan untuk lalu lintas transaksi antar wallet adalah USDT (US Tether)', link : '', sub : []},
            {title : 'Exchanger Rate yang dijadikan acuan adalah Opening Rate dari Bloomberg', link : 'https://www.bloomberg.com/quote/EURUSD:CUR', sub : []},
            {title : 'Contoh transaksi:', link : '', sub : [
                {point : 'a.', title : 'Transaksi per tanggal 30 Juli adalah EURUSD 1.1753'},
                {point : 'b.', title : 'Apabila hendak melakukan pembelian paket senilai 10.000 EUR, maka user dipersilahkan melakukan transfer ke wallet yang sudah disediakansenilai 11.753 USDT'},
            ]},
        ]
        await this.setState({terms_condition : terms_condition})
    }

    render(){
        return (
        <div className="login-page p-4" style={{height : '100vh'}}>
            <HeaderNav title={'Terms & Condition'} show_back={true} />
            
            <div className="row m-0 mt-3">
                <div className="col-12 p-0">
                    {
                        this.state.terms_condition.map((data, index)=>(
                            <>
                            <p key={index}>{index+1}. {data.title}
                            {
                                data.link != '' ? 
                                (  
                                    <>
                                    <br/><a href={data.link} className="text-white text-break" target="_blank">(<u>{data.link}</u>)</a>
                                    </>
                                ) : <></>
                            }
                            {
                                data.sub.map((data, index1)=>(
                                    <p className="m-0" key={index1}>{data.point} {data.title}</p>
                                ))
                            }
                            </p>
                            </>
                        ))
                    }
                </div>
            </div>
            
        </div>
        );
    }
}