import React from 'react';
import Base from '../Utils/Base';

export default class BaseLayout extends Base{
    render(){
        return (
            <footer className="footer-container bg-white text-center">
                    
                <div className="container p-3" style={{borderTop : '0.25rem solid #D0A049'}}>
                    <div className="row">
    
                        <div className="col">
                            <small className="m-0" style={{color : 'black'}}>Design by QuantumTri @2020</small>
                        </div>
    
                    </div>
                </div>
    
            </footer>
        )
    }
}