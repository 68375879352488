import React from 'react';
import Base from '../Utils/Base';
import {Line} from 'react-chartjs-2'

export default class Chart extends Base{

    render(){
        return (
            <div className="chart">
                <Line 
                data={this.props.chart_data}
                options={{
                    responsive : true,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                        ticks: {
                            min: this.props.min_chart,
                            }
                        }]
                    }
                }} />
            </div>
        );
    }
}
