import React from 'react';
import Base from '../Utils/Base';

import HeaderNav from '../Components/headerNav'
import Alert from '../Components/alert'
import ModalAlert from '../Components/modalAlert'

export default class ChangePassword extends Base{
    state = {
        change_pass : {password : '', re_type_pass : ''},
        alert : {
            message : '', type : '', is_alert : false
        },
        alertMessage : '',
        is_disable : false,
        token : '',
    }

    async componentDidMount() {
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

    }
    
    async submit(){
        if(this.state.change_pass.password == ''){
            this.alert_show('New Password cannot be null', 'danger')
        }
        else if(!this.state.change_pass.re_type_pass){
            this.alert_show('Re-Type Password cannot be null', 'danger')
        }
        else if(this.state.change_pass.re_type_pass != this.state.change_pass.password){
            this.alert_show('Password not match', 'danger')
        }
        else{
            this.alert_show('Please Wait...', 'info')
            
            var data = this.state.change_pass

            try {
                await this.setState({is_disable : true})
                var response = await this.axios.put(this.url + '/auth/change-password', data, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : this.state.token
                  },
                });

                if(response.data.status == 'success'){
                    this.alert_show('Change Password Success. Please Wait...', 'success')

                    setTimeout(()=>{
                        window.location.href = '/'
                    }, 2000);
                }
                else{
                    await this.setState({is_disable : false})
                    this.alert_show(response.data.message, 'danger')
                }

            } 
            catch (e) {
                await this.setState({is_disable : false})
                await this.setState({alertMessage : e.message})
                this.$('modalAlert').modal('show')
            }
        }
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async changeInput(value, type){
        await this.setState({alert : {message : '', type : '', is_alert : false}})

        var change_pass = this.state.change_pass
        change_pass[type] = value
        await this.setState({change_pass : change_pass})
    }

    render(){
        return (
        <div className="change_pass-page p-4" style={{height : '100vh'}}>
            <HeaderNav title={'Change Password'} show_back={false} />

            
            <form className="pt-4" autoComplete="off">
                {
                    this.state.alert.is_alert ?
                    <Alert type={this.state.alert.type} message={this.state.alert.message} /> : <></>
                }
                <div className="input-group mb-2">
                    <input type="password" className="form-control" placeholder="Password" value={this.state.change_pass.password} onChange={(value)=>this.changeInput(value.target.value, 'password')} />
                </div>
                <div className="input-group mb-2">
                    <input type="password" className="form-control" placeholder="Re-Type Password" value={this.state.change_pass.re_type_pass} onChange={(value)=>this.changeInput(value.target.value, 're_type_pass')} />
                </div>

                <div className="pt-5">
                    <button className="btn btn-primary w-100" disabled={this.state.is_disable} type='button' onClick={()=>this.submit()}>Submit</button>
                </div>

            </form>

            <ModalAlert title={'Alert'} message={this.state.alertMessage} />

        </div>
        );
    }
}
// export default App;
