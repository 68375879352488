import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import HeaderNav from '../Components/headerNav'
import ModalAlert from '../Components/modalAlert'

export default class HistoryTransaction extends Base{
    state = {
        token : '',
        detail_data_arr : [],
        detail_summary_arr : [],
        modal_alert : {title : '', message : ''},
        data_wallet : {info : '', status : '', date_transaction_format : '', amount : 0, attachment_url : null},
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})
        
        await this.get_data()
        var detail_data_arr = [
            {id : 0, title : 'Type of Transaction', value : this.state.data_wallet.info},
            {id : 1, title : 'Transaction Status', value : this.state.data_wallet.status},
            {id : 2, title : 'Date of Transaction', value : this.state.data_wallet.date_transaction_format},
            // {id : 3, title : 'Date Processed', value : 'test'},
        ]

        var detail_summary_arr = [
            {id : 0, title : this.state.data_wallet.info, value : this.state.data_wallet.amount},
            // {id : 1, title : 'Withdraw Fee', value : '5.00'},
        ]

        await this.setState({detail_data_arr : detail_data_arr, detail_summary_arr : detail_summary_arr})
    }

    async get_data(){
        try {
            var response = await this.axios.get(this.url + '/trader-wallet-history?id=' + this.props.match.params.id, {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
                },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                data.date_transaction_format = this.moment(data.transaction_date).format('DD-MM-YY')
                await this.setState({data_wallet : data})
            }
        } 
        catch (e) {
            var modal_alert = {title : 'Alert', message : e.message}
            await this.setState({modal_alert : modal_alert})
            this.$('#modalAlert').modal('show')
        }
    }

    async upload_receipt(){
        await localStorage.setItem('trader_wallet_id', this.props.match.params.id)
        window.location.href = '/cash/top-up/upload-receipt'
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Detail Transaction'} show_back={true} />
                    
                    <div className="row m-0 mt-5">

                        <div className="col-12 p-0 mt-2">
                            <div className="row m-0">

                                {
                                    this.state.detail_data_arr.map((data, index)=>(
                                        <div className={"col-12" + (index != 0 ? ' mt-2' : '')}>
                                            <div className="row">
                                                <div className="col-6 p-0">
                                                    <p className="m-0">{data.title}</p>
                                                </div>
                                                <div className="col-6 p-0 text-right">
                                                    <p className="m-0 text-capitalize">{data.value}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <div className="col-12 mt-2">
                                    <div className="row">
                                        <div className="col-6 p-0">
                                            <p className="m-0">Receipt</p>
                                        </div>
                                        <div className="col-6 p-0 text-right">
                                        {
                                            this.state.data_wallet.attachment_url != null ?
                                            <div className="ratio-11 mt-3" style={{backgroundImage : "url(" + this.url + '/image/trader-wallet?file_name=' + this.state.data_wallet.attachment_url +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain'}}></div>
                                            : <>-</>
                                        }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-12 p-3 mt-3 bg-dark border-top-contain">
                            <div className="row m-0">

                                    {
                                        this.state.detail_summary_arr.map((data, index)=>(
                                            <div className={"col-12" + (index != 0 ? ' mt-2' : '')}>
                                                <div className="row">
                                                    <div className="col-6 p-0">
                                                        <p className="m-0 text-capitalize">{data.title}</p>
                                                    </div>
                                                    <div className="col-6 p-0 text-right">
                                                        <p className="m-0">{this.euro_currency} {parseFloat(data.value).toLocaleString(this.currency_format)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                        </div>

                        {
                            this.state.data_wallet.info == 'top up' && this.state.data_wallet.attachment_url == null ?
                            <div className="col-12 p-0 mt-3">
                                <button className="btn btn-primary w-100" onClick={()=>this.upload_receipt()}>Upload Receipt</button>
                            </div> : <></>
                        }

                    </div>

                </div>

                <ModalAlert title={this.state.modal_alert.title} message={this.state.modal_alert.message} />

            </div>
            </>
        )
    }
}