import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'
import BottomButton from '../../Components/bottomButton'

export default class WithdrawSubmitted extends Base{
    state = {
        token : '',
        landing_contact_us : {fullname : '', email : '', message : ''},
        type : 'Investment'
    }

    async componentDidMount(){
        var token = '123'
        await this.setState({token : token})
    }

    async toHome(){
        window.location.href = '/'
    }

    render(){
        return (
            <>
            <div className="p-4">

                <HeaderNav title={'Submitted'} show_back={true} />

                <div className="row m-0">

                    <div className="col-12 p-3 mt-4 bg-dark">
                        <p className="m-0">
                            <b>Thank You</b><br />
                            Your withdrawal order has successful.<br />
                            You may received fund within several days
                        </p>
                    </div>

                    <BottomButton buttonAction={()=>this.toHome()} is_disabled={this.state.is_disable} title={'Back to Home'} />

                </div>

            </div>
            </>
        )
    }
}