import React from 'react';
import Base from '../../Utils/Base';

import HeaderNav from '../../Components/headerNav'

import Alert from '../../Components/alert'
import ModalAlert from '../../Components/modalAlert'

export default class ForgetPassword extends Base{
    state = {
        email : '',
        alertMessage : '',
        alert : {
            message : '', type : '', is_alert : false
        },
        modal_alert : {title : '', message : ''},
        is_disable : false
    }

    async componentDidMount() {
    }
    
    async next(){
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if(this.state.email == ''){
            this.alert_show('Email cannot be null', 'danger')
        }
        else if(!this.state.email.match(mailformat)){
            this.alert_show('Email format is wrong', 'danger')
        }
        else{
            await localStorage.setItem("forgotPass_email", this.state.email)

            window.location.href = '/auth/security-question'
        }
    }

    async alert_show(message, type){
        var alert = this.state.alert
        alert['message'] = message
        alert['type'] = type
        alert['is_alert'] = true
        await this.setState({alert : alert})
    }

    async changeInput(value){
        await this.setState({alert : {message : '', type : '', is_alert : false}})
        await this.setState({email : value})
    }

    render(){
        return (
        <div className="p-4" style={{height : '100vh'}}>
            <HeaderNav title={'Forgot Password'} show_back={true} />

            <p className="mt-5">
            Please enter your registered email so we can send you the link for resetting the password.
            </p>
            
            <form className="pt-4" autoComplete="off">
                {
                    this.state.alert.is_alert ?
                    <Alert type="danger" message={this.state.alert.message} /> : <></>
                }
                <div className="input-group mb-4">
                    <input type="text" className="form-control form-control-lg" placeholder="Email" value={this.state.email} onChange={(value)=>this.changeInput(value.target.value)} />
                </div>

                <div className="pt-5">
                    <button className="btn btn-primary w-100" disabled={this.state.is_disable} type='button' onClick={()=>this.next()}>Next</button>
                </div>

            </form>

            <ModalAlert title={this.state.modal_alert.title} message={this.state.modal_alert.message} />
        </div>
        );
    }
}
// export default App;
