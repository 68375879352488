import React from 'react'
import Base from '../Utils/Base'

export default class CashInvestmentSection extends Base{
    render(){
        return (
            <div className="p-3 bg-dark border-top-contain">
                <small className="title m-0">{this.props.type}</small>
                <h6>{this.euro_currency} {parseFloat(this.props.amount).toLocaleString(this.currency_format)}</h6>
            </div>
        )
    }
}