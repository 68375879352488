import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'
import Sort from '../Components/sort'

export default class Cash extends Base{
    state = {
        token : '',
        data_arr : [],
        wallet_info : {},
        user_data : {wallet_data : {cash : 0}},
        sort : ['recent', 'oldest'],
        sort_selected : '',
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var sort_selected = this.state.sort[0]
        await this.setState({token : token, sort_selected : sort_selected})

        await this.get_auth()
        await this.get_data()
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
                },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                data.wallet_data = data.trader[0].trader_wallet[0]
                await this.setState({user_data : data})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async get_data(){
        try {
            var response = await this.axios.get(this.url + '/trader-wallet-history?id=&type=cash&sort='+this.state.sort_selected, {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
                },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                var arr = []
                for(var x in data){
                    data[x].transaction_date_format = this.moment(data[x].transaction_date).format('MM/DD')
                    if(data[x].status == 'success'){
                        arr.push(data[x])
                    }
                }
                await this.setState({data_arr : arr})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async actionBtn(type){
        window.location.href = '/cash/' + type
    }

    async change_sort(sort){
        await this.setState({data_arr : [], sort_selected : sort})
        await this.get_data()
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Cash'} show_back={true} />
                    
                    <div className="row m-0 mt-5">
                        <div className="col-12 p-0">
                            <h4>{this.euro_currency} {parseFloat(this.state.user_data.wallet_data.cash).toLocaleString(this.currency_format)}</h4>
                        </div>

                        <div className="col-12 p-0 mt-3">
                            <div className="row m-0">
                                <div className="col p-0">
                                    {this.state.data_arr.length} Transaction
                                </div>
                                <div className="col p-0 text-right">
                                    <Sort sort_selected={this.state.sort_selected} sort_arr={this.state.sort} changeSort={(data)=>this.change_sort(data)} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 p-0 mt-2">
                            <div className="row m-0">

                                {
                                    this.state.data_arr.map((data, index)=>(
                                        <div className="col-12 p-0 py-3 transaction-list" key={index}>
                                            <div className="row m-0">
                                                <div className="col-auto p-0">
                                                    <span className="text-secondary">{data.transaction_date_format}</span>
                                                </div>
                                                <div className="col">
                                                    <p className="m-0 text-capitalize"><b>{data.info}</b></p>
                                                </div>
                                                <div className="col-auto p-0 text-right">
                                                    <p className="m-0">
                                                        <span className={"text-" + (data.type == 'in' ? 'success' : 'danger')}>
                                                        {data.type == 'in' ? '+' : '-'}
                                                        </span>
                                                        {this.euro_currency} {parseFloat(data.amount).toLocaleString(this.currency_format)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                

                                <div className="col-12 p-0 mt-3">
                                    <div className="row m-0">
                                        <div className="col-6 p-0 pr-1">
                                            <button className="btn btn-primary btn-sm w-100" onClick={()=>this.actionBtn('top-up')}>Top Up</button>
                                        </div>
                                        <div className="col-6 p-0 pl-1">
                                            <button className="btn btn-primary btn-sm w-100" onClick={()=>this.actionBtn('withdraw')}>Withdraw</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
            </>
        )
    }
}