import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'

export default class TopUpSubmit extends Base{
    state = {
        data_arr : [],
    }

    async actionBtn(type){
        window.location.href = (type == 'home') ? '/' : '/cash/top-up/upload-receipt'
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Submit'} show_back={true} />

                    <div className="row m-0">
                        <div className="col-12 p-0 mt-4">
                            <p>You have succeed Topping Up your cash</p>
                        </div>
                    </div>
                    
                    <div className="row m-0">

                        <div className="fixed-bottom p-4 bg-black">
                            <button className="btn btn-primary w-100" type='button' onClick={()=>this.actionBtn('home')}>Go to Home</button>
                            <button className="btn btn-primary w-100 mt-2" type='button' onClick={()=>this.actionBtn('upload-receipt')}>Upload Proof</button>
                        </div>
                    </div>
                    
                </div>
            </div>
            </>
        )
    }
}