import React from 'react'
import Base from '../Utils/Base'

import HeaderNav from '../Components/headerNav'

export default class PackageSuccess extends Base{

    state = {
        token : '',
    }

    async componentDidMount(){
        var token = ''
        await this.setState({token : token})
    }

    async toHome(){
        window.location.href = '/'
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={'Submit'} show_back={true} />

                    <div className="row m-0 mt-4">
                        <div className="col-12 p-0">
                            
                            <div className="col-12 p-3 bg-dark border-top-contain">
                                <p className="m-0 mt-2">
                                <b>Thank You</b><br/>
                                You have succeed adding a package
                                </p>
                            </div>

                            <div className="col-12 p-0 mt-4">
                                <button class="btn btn-primary w-100" onClick={()=>this.toHome()}>Go to Home</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            </>
        )
    }
}