import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import HeaderNav from '../Components/headerNav'

export default class HelpDetail extends Base{
    state = {
        title : '',
    }

    async componentDidMount(){
        console.log(this.props.match.params)
        // var title = (this.props.match.params.type == 'make-deposit' ? 'Make a Deposit' : 'Make a Withdraw')
        // await this.setState({title : title})
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav title={this.state.title} show_back={true} />
                                        
                    {/* <div className="row mt-3">
                        <div className="col-12 py-3">
                            <i class=" mr-2"></i>Make a Deposit
                        </div>
                    </div> */}

                </div>
            </div>
            </>
        )
    }
}