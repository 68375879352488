import React from 'react'
import Base from '../../Utils/Base'

import HeaderNav from '../../Components/headerNav'
import BottomButton from '../../Components/bottomButton'

export default class WithdrawInvoice extends Base{
    state = {
        token : '',
        landing_contact_us : {fullname : '', email : '', message : ''},
        type : 'Investment',
        withdraw_amount : 0,
        is_disable : false,
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        var withdraw_amount = await localStorage.getItem('withdraw_amount')
        await this.setState({token : token, withdraw_amount : withdraw_amount})
    }

    async toNext(){
        var data = {}
        data.amount = this.state.withdraw_amount

        try {
            await this.setState({is_disable : true})
            var response = await this.axios.post(this.url + '/transaction/withdraw', data, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                await localStorage.removeItem('withdraw_amount')
                window.location.href = '/cash/withdraw/submitted'
            }
            else{
                await this.setState({is_disable : false})
            }

        } 
        catch (e) {
            console.log(e.message)
        }
    }

    render(){
        return (
            <>
            <div className="p-4">

                <HeaderNav title={'Withdraw Invoice'} show_back={true} />

                <div className="row m-0">

                    <div className="col-12 p-0 mt-4 pb-4 border-bottom-gray">
                        <div className="row m-0">
                            
                            <div className="col p-0">
                                <p className="m-0">Withdraw</p>
                            </div>
                            <div className="col p-0 text-right">
                                <p className="m-0">{this.euro_currency} {parseFloat(this.state.withdraw_amount).toLocaleString(this.currency_format)}</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-12 p-0 mt-4">
                        <div className="row m-0">
                            
                            <div className="col p-0">
                                <p className="m-0">Total</p>
                            </div>
                            <div className="col p-0 text-right">
                                <p className="m-0">{this.euro_currency} {parseFloat(this.state.withdraw_amount).toLocaleString(this.currency_format)}</p>
                            </div>

                        </div>
                    </div>

                    <BottomButton buttonAction={()=>this.toNext()} is_disabled={this.state.is_disable} title={'Next'} />

                </div>

            </div>
            </>
        )
    }
}