import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import LandingTitle from './SectionTitle'
import Footer from '../Layout/footer'

export default class Landing extends Base{
    async joinNow(){
        window.location.href = '/auth/login'
    }

    render(){
        return (
            <div>
                <section id="head">
                    <div className="row m-0">
                        <div className="col-12 sectionLanding text-center ratio1" style={{backgroundImage : "url(" + this.landing1 +")"}}>
                    
                            <div className="d-flex align-middle h-100 align-items-center">
                                <div className="row">
                                    <div className="col-12">
                                    <div className="ratio-31" style={{backgroundImage : "url(" + this.logo +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain'}}></div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <h1>Build Future Together</h1>
                                    </div>
                                    <div className="col-12 py-4">
                                        <button className="btn btn-primary" onClick={()=>this.joinNow()}>Join Now</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="contentLanding" style={{padding : '5rem 2rem 2rem'}}>
                    <div className="row m-0">
                        <div className="col-12 sectionLanding">
                            <LandingTitle smallTitle={'Professional'} title={'MANAGEMENT PROGRAM'} />

                            <p className="pt-4">The strategy of the software can be explained quite simple the program <b>Make Sure</b> the loss is small to no loss during a bearish market and the <b>WIN</b> is <b>VERY HIGH</b> during bullish markets. The liquid money is stored in <b>{this.euro_currency}</b> and also in Europe.</p>
                            <div className="ratio-43 pt-4" style={{backgroundImage : "url(" + this.landing2 +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain'}}></div>

                        </div>
                        <div className="col-12 sectionLanding">
                            <LandingTitle smallTitle={'Marketing Plan &'} title={'REFERRAL PROGRAM'} />

                            <p className="pt-4"><b>A lucrative numbers game!</b> As a partner of <b>VUE<sup>99</sup></b>, we expect to you to put us in contact with people in your network, who could be interested in our services. You are in no way expected to <b>"Sell"</b> us but rather to just provide information about our services and what we stand for. We will take care of the rest. If any person you introduce us to end up becoming and investor, you are eligible for <b>remuneration.</b></p>

                            <div className="ratio-43" style={{backgroundImage : "url(" + this.landing3 +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain'}}></div>
                        </div>

                        <div className="col-12 sectionLanding pt-5">
                            <LandingTitle smallTitle={['VUE',<sup>99</sup>,' ATCC']} title={'AUTO TRADING CRYPTO CURRENCY'} />

                            <div className="ratio-43 mt-4" style={{backgroundImage : "url(" + this.landing4 +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain'}}></div>

                            <div className="pt-4">
                                <p className="m-0">+ Fully operated and consistently 24 hours a week.</p>
                                <p className="m-0 text-primary">+ Trading without knowledge & prior experience.</p>
                                <p className="m-0">+ Automatically operating & executing transactions.</p>
                                <p className="m-0 text-primary">+ Have an accurate trading strategy & error measurement.</p>
                                <p className="m-0">+ Have a specially designed logarithm to produce a consistent portfolio.</p>
                            </div>
                        </div>
                        
                        {/* <div className="col-12 sectionLanding mt-5">
                            <LandingTitle smallTitle={'Meet The'} title={'Founder'} />

                            <div className="row">

                                <div className="col-12">
                                    <div>
                                        <h4>Mikkel Mørch</h4>
                                        <p className="text-primary">Executive Director</p>
                                    </div>
                                    <p className="m-0">Experienced business developer, board chairman / member and managing director with decades of managerial, business and financial analysis experience from different sectors. Has recently bought, developed and resold companies to the benefit of all shareholders, and has director-level experience at both large global corporations and smaller start-ups.</p>
                                </div>

                                <div className="col-12 mt-5">
                                    <div>
                                        <h4>Ulrik K. Lykke</h4>
                                        <p className="text-primary">Executive Director</p>
                                    </div>
                                    <p className="m-0">Experienced entrepreuner who has created and managed multiple technology start-ups in the past. Capable and experienced investor in crypto assets. Ulrik has been keenly interested in Bitcoin since he first discovered the phenomenon in 2011.</p>
                                </div>

                            </div>

                        </div> */}

                    </div>


                </section>

                <section id="join" className="mt-5">
                    <div className="row landingBox p-5 m-0">
                        <div className="col-12 text-center">
                            <div className="ratio-169" style={{backgroundImage : "url(" + this.landing_quote +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain'}}></div>
                            {/* image quote */}
                        </div>
                        <div className="col-12 text-center py-4">
                            <button className="btn btn-primary" onClick={()=>this.joinNow()}>Join Now</button>
                        </div>
                    </div>
                </section>

                <section id="contactUs" style={{padding : '5rem 2rem 2rem'}}>
                    <LandingTitle smallTitle={'For more Information'} title={'CONTACT US'} />
                    
                    <form className="pt-5" autoComplete="off">
                        <div className="input-group mb-5">
                            <input type="text" className="form-control" placeholder="Full Name" value={this.props.contact_us.fullname} onChange={(value)=>this.props.changeContactUs(value.target.value, 'fullname')} />
                        </div>
                        <div className="input-group mb-5">
                            <input type="email" className="form-control" placeholder="Email" value={this.props.contact_us.email} onChange={(value)=>this.props.changeContactUs(value.target.value, 'email')} />
                        </div>
                        <div className="input-group mb-4">
                            <textarea className="form-control" rows='5' placeholder="Messages" value={this.props.contact_us.message} onChange={(value)=>this.props.changeContactUs(value.target.value, 'message')} ></textarea>
                        </div>

                        <div className="pt-2">
                            <button className="btn btn-primary" type='button' onClick={()=>this.props.submit_more_info()}>Submit</button>
                        </div>
                    </form>

                </section>

                <section id="footer" className="mt-4" style={{padding : '0 2rem 2rem'}}>
                    <div className="row m-0">
                        <div className="col-12 p-0 mb-4">
                            <div className="ratio-31" style={{backgroundImage : "url(" + this.logo +")", backgroundRepeat : 'no-repeat', backgroundSize : 'contain', width : '30%', paddingBottom : 'calc(100% / 4)'}}></div>
                        </div>
                        <a href="#!" className="col-12 p-0 mb-2 text-white">About Us</a>
                        <a href="#!" className="col-12 p-0 mb-2 text-white">Contact Us</a>
                        <a href="#!" className="col-12 p-0 mb-2 text-white">FAQ</a>
                        <a href="#!" className="col-12 p-0 mb-2 text-white">Pricing</a>
                        <a href="#!" className="col-12 p-0 mb-2 text-white">Social Media</a>
                    </div>
                </section>

                <Footer />
            </div>
        )
    }
}