import React from 'react'
import Base from '../Utils/Base'
import { Redirect, useHistory } from "react-router-dom";

import HeaderNav from '../Components/headerNav'

export default class ChatRoom extends Base{
    async joinNow(){
        window.location.href = '/auth/login'
    }

    async componentDidMount(){
        var token = '123'
        await this.setState({token : token})
    }

    render(){
        return (
            <>
            <div className="row m-0">
                <div className="col-12 p-4">
                    <HeaderNav show_back={true} />
                    
                    <div className="row m-0 mt-3">
                        <div className="col-12 p-0 py-3 border-bottom-gray">
                            
                        </div>

                        <div className="fixed-bottom p-4">
                            <form className="pt-4" autoComplete="off">
                                <div class="input-group">
                                    <input type="text" class="form-control form-control-sm" placeholder="Type New Messages" aria-describedby="basic-addon1" />
                                    <div class="input-group-append">
                                        <span class="input-group-text-search" id="basic-addon1"><i class="fas fa-paper-plane"></i></span>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
            </>
        )
    }
}