import React from 'react'
import Base from '../Utils/Base'

import Landing from '../Landing'
import HomeBasic from '../Home/Basic'

import {Line} from 'react-chartjs-2'

import WelcomeSection from './section_welcome'
import CashInvestmentSection from './section_cash_investment'

import Chart from '../Components/Chart'

export default class IndexHome extends Base{
    state = {
        token : '',
        landing_contact_us : {fullname : '', email : '', message : ''},
        user_data : {join_at : '', role_user : {role : {role_name : ''}}, name : '', wallet_data : {cash : ''}},
        data_chart1 : {
            labels : ["M", "T", "W", "T", "F", "S", "S"],
            datasets : [
                {
                    label : 'Test1',
                    backgroundColor : "rgba(255,204,102, .75)",
                    data : [250,200,110,120,320,200,120]
                }
            ]
        },
        ark_arr : [],
        ark_chart : [],
        investment_arr : [],
        investment_chart : [],
        ark_min : 0,
        investment_min : 100,
    }

    async componentDidMount(){
        var token = await localStorage.getItem('token')
        await this.setState({token : token})

        await this.get_auth()
        await this.get_graph1()
        await this.get_graph2()
    }

    async get_auth(){
        try {
            var response = await this.axios.get(this.url + '/auth/profile', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data
                data.wallet_data = data.trader[0].trader_wallet[0]
                data.role_user = data.role[0]
                await this.setState({user_data : data})
            }
            else{
                await localStorage.clear()
                await this.setState({token : ''})
                // window.location.reload()
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async get_graph1(){
        try {
            var response = await this.axios.get(this.url + '/transaction', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                data.reverse()
                var data_arr = [], data_arr1 = []
                var date_arr = []

                var total_data = data.length
                var count_date = 0
                if(total_data < 7){
                    count_date = 7 - total_data
                }
                
                for(var x=count_date; x>0;x--){
                    var day_before = this.moment().subtract(x, 'days').format('dddd')[0]
                    var total = ''

                    data_arr.push('')
                    date_arr.push(day_before)
                }

                for(var x in data){
                    var graph = {}
                    graph.name_date = this.moment(data[x].date).format('ddd')[0]
                    graph.total = data[x].total
                    graph.transactionperdate = data[x].transactionperdate

                    data_arr.push(graph.total)
                    data_arr1.push(graph.total)
                    date_arr.push(graph.name_date)
                }

                var investment_chart = {
                    labels : date_arr,
                    datasets : [
                        {
                            label : '',
                            backgroundColor : "rgba(255,204,102, .75)",
                            data : data_arr
                        }
                    ]
                }

                await this.setState({investment_arr : data, investment_chart : investment_chart})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async get_graph2(){
        try {
            var response = await this.axios.get(this.url + '/log-data', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization' : this.state.token
              },
            });

            if(response.data.status == 'success'){
                var data = response.data.data.data
                console.log(data)
                data.reverse()
                var data_arr = [], data_arr1 = []
                var date_arr = []
                for(var x in data){
                    var graph = {}
                    graph.name_date = this.moment(data[x].created_date).format('ddd')[0]
                    data_arr.push(data[x].performance)
                    data_arr1.push(data[x].performance)
                    date_arr.push(graph.name_date)
                }

                // if(data_arr1.length > 0){
                //     data_arr1.sort()
                //     this.setState({ark_min : parseFloat(data_arr1[0])})
                // }

                var ark_chart = {
                    labels : date_arr,
                    datasets : [
                        {
                            label : '',
                            backgroundColor : "rgba(255,204,102, .75)",
                            data : data_arr
                        }
                    ]
                }

                await this.setState({ark_arr : data, ark_chart : ark_chart})
            }
        } 
        catch (e) {
            console.log(e.message)
        }
    }

    async submit_more_info(){
        console.log(this.state.landing_contact_us)
    }

    async changeInput_contactUs(value, type){
        var contact_us = this.state.landing_contact_us
        contact_us[type] = value
        await this.setState({landing_contact_us : contact_us})
    }

    async upgradePlan(){
        window.location.href = '/package'
    }

    render(){
        return (
            <>
            {
                this.state.token == '' || this.state.token == null ?
                <Landing contact_us={this.state.landing_contact_us} submit_more_info={()=>this.submit_more_info()} changeContactUs={(value, type)=>this.changeInput_contactUs(value, type)} />
                : 
                <>
                    {/* <HomeBasic user_data={this.state.user_data} /> */}
                    <div className="row m-0">
                        <div className="col-12 p-4">
                            
                            <div className="row m-0">
                                <WelcomeSection type_acc={this.state.type} user_data={this.state.user_data} />

                                {
                                    this.state.user_data.role_user.role.role_name == 'user.basic' ?
                                    <>
                                    <div className="col-12 p-3 bg-dark  mt-5">
                                        <h5 className="title m-0">Basic's</h5>
                                        <p className="m-0 mt-2">Upgrade Now and get more benefits</p>
                                        <button className="btn btn-primary mt-3"><b>Coming Soon...</b></button>
                                        {/* <button className="btn btn-primary mt-3" onClick={()=>this.upgradePlan()}>Upgrade</button> */}
                                    </div>
                                    <div className="col-12 mt-4 p-0">
                                        <CashInvestmentSection type={'Cash'} amount={this.state.user_data.wallet_data.cash} />
                                    </div>
                                    </>
                                    :
                                    <>

                                    <div className="col-12 p-0 mt-4">
                                        <p>Guaranteed Profit Graph</p>

                                        <Chart chart_data={this.state.investment_chart} min_chart={this.state.investment_min} />

                                    </div>
                                    <div className="col-12 p-0 mt-4">
                                        <p>Performance of your fund Graph</p>

                                        <Chart chart_data={this.state.ark_chart} min_chart={this.state.ark_min} />
                                    </div>

                                    <div className="col-12">
                                    {/* <Line width={100} height={50} /> */}
                                    </div>


                                    <div className="col-6 mt-4 p-0 pr-2">
                                        <CashInvestmentSection type={'Cash'} amount={this.state.user_data.wallet_data.cash} />
                                    </div>
                                    <div className="col-6 mt-4 p-0 pl-2">
                                        <CashInvestmentSection type={'Investment'} amount={this.state.user_data.wallet_data.investment} />
                                    </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
            

                    <div className="row m-0">

                        {/* <div className="col-12 p-4">
                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <h5 className="m-0">Referrenced by</h5>

                                    <div className="row m-0 mt-3">

                                        <div className="col-auto p-0">
                                            <img src={this.no_user_image} className="img-profile-picture" />
                                        </div>
                                        <div className="col py-2">
                                            <h5 className="title m-0">Name</h5>
                                            <p className="m-0">since March</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 p-3 bg-dark mt-4 border-top-contain">
                                    <p className="m-0 mt-2">Invite new member to get more benefits.</p>
                                    <button className="btn btn-primary mt-3">Share</button>
                                </div>

                            </div>

                        </div> */}

                    </div>
                </>

            }

            </>
        )
    }
}