import React from 'react';
import Base from '../Utils/Base';

export default class Sort extends Base{
  render(){
    return (
        <>
        <div className="dropdown">
            <button className="btn btn-outline-primary btn-sm dropdown-toggle text-capitalize" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.props.sort_selected}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {
                this.props.sort_arr.map((data, index)=>(
                    <a className="dropdown-item text-capitalize" href="#!" key={index} onClick={()=>this.props.changeSort(data)}>{data}</a>
                ))
            }
            </div>
        </div>
        </>
    );
  }
}
