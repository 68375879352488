import React from 'react';
import Base from '../Utils/Base';

export default class ReferralCode extends Base{
    async goBack(){
        window.location.reload()
        window.history.back()
    }

    render(){
        return (
            <>
            {
                this.props.show_back ?
                <h4 className="mb-3" onClick={()=>this.goBack()}><i className="fas fa-chevron-left text-primary"></i></h4>
                : <></>
            }
            <h3 className="text-primary">{this.props.title}</h3>
            </>
        );
    }
}
