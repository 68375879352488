import React from 'react'
import Base from '../Utils/Base'

export default class SectionTitle extends Base{
    render(){
        return (
            <>
                <h5>{this.props.smallTitle}</h5>
                <h4 className="text-primary">{this.props.title}//</h4>
            </>
        )
    }
}