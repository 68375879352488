import React from 'react';
import Base from '../Utils/Base';
import Router from '../Router/router'
import Navbar from './navbar'
import SideBar from './sidebar'

export default class BaseLayout extends Base{

  state = {
    token : '',
    user_data : {role_user : {}},
    count_notif : 0,
  }
  
  async componentDidMount(){
      var token = await localStorage.getItem('token')
      await this.setState({token : token})

      if(token != null || token != ''){
        await this.get_auth()
        await this.get_notif()
  
        const socket = this.io(this.host + ':6001', {})
        
        socket.emit('subscribe', {
          channel: 'vue99_database_notification.' + this.state.user_data.id,
        }).on('notification_web', function(channel, data) {
          console.log(data);
          Notification.requestPermission( permission => {
            let notification = new Notification('New Notification!', {
              body: data.notif.message
            })
            notification.onclick = () => {
            }
          })
        })

    }
  }

  async get_auth(){
    try {
      var response = await this.axios.get(this.url + '/auth/profile', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : this.state.token
        },
      });
      
      if(response.data.status == 'success'){
        var data = response.data.data
        data.role_user = data.role[0].role
        
        await this.setState({user_data : data})
      }
      else{
        await localStorage.clear()
        await this.setState({token : ''})
      }
    } 
    catch (e) {
      console.log(e.message)
    }
  }

  async get_notif(){
    try {
      var response = await this.axios.get(this.url + '/notif', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : this.state.token
        },
      });
      
      if(response.data.status == 'success'){
        var data = response.data.data.data
        // var count_notif = (data.length > 99 ? '99+' : data.length)
        var unread_notif = []
        for(var x in data){
          if(data[x].is_read != 'Y'){
            unread_notif.push(data[x])
          }
        }
        await this.setState({count_notif : unread_notif.length})
      }
      else{
        await localStorage.clear()
        await this.setState({token : ''})
      }
    } 
    catch (e) {
      console.log(e.message)
    }
  }

  render(){
    return (
      <div className="wrapper">

        <div className="overlay"></div>

        <div className="content">
          {
            this.state.token == '' || this.state.token == null ?
            <></> : <Navbar count_notif={this.state.count_notif} />
          }
          <Router />
        </div>
      </div>
    );
  }
}
